import { PathType } from '@property-folders/contract/yjs-schema/model';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { YjsDocContext } from '../context/YjsDocContext';
import { FormContext } from '../context/FormContext';
import { normalisePathToStrArray } from '@property-folders/common/util/pathHandling';
import { ValidationReducerState } from '@property-folders/common/redux-reducers/validation';
import { ValidationConstruct } from '@property-folders/common/yjs-schema/property/validation/process-validation';

export function useFocusErrorList({
  docName,
  rootKey,
  formName
}: {
  docName: string | undefined,
  rootKey: string | undefined,
  formName?: string
}): string[] {
  return useSelector((state: { validation: ValidationReducerState }) => {
    if (formName) {
      return state?.validation?.focusErrList?.[docName ?? '']?.[rootKey ?? '']?.[formName] || [] as string[];
    }

    return Object.values(state?.validation?.focusErrList?.[docName ?? '']?.[rootKey ?? ''] || {}).flat() as string[];
  });
}

export function useSpecificErrorsForPath(path: PathType, keyOverrides?: {
  docName?: string | undefined,
  rootKey?: string | undefined,
  formName?: string | undefined
}) {

  const { docName, transactionRootKey } = useContext(YjsDocContext);
  const { formName } = useContext(FormContext);
  const docId = keyOverrides?.docName || docName;
  const docKey = keyOverrides?.rootKey || transactionRootKey;
  const formCode = keyOverrides?.formName || formName;

  return useSelector((state: { validation: ValidationReducerState }) => {
    return extractValidationResult(
      state?.validation,
      path,
      docId ?? '',
      docKey ?? '',
      formCode ?? ''
    );
  });

}

export function extractValidationResult(
  validation: Pick<ValidationReducerState, 'vtrees'> | undefined,
  path: PathType,
  docId: string,
  docKey: string,
  formCode: string
): undefined | ValidationConstruct {
  const treeTop = validation?.vtrees?.[docId]?.[docKey]?.[formCode];
  if (!treeTop) return undefined;

  const pathSegments = normalisePathToStrArray(path);
  let current = treeTop;
  for (const segment of pathSegments) {
    if (current[segment]) {
      current = current[segment];
    } else {
      return undefined;
    }
  }
  return current?._validationResult;
}
