import { TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from './useImmerYjs';
import { FormCodeUnion, FormInstance, META_APPEND, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { Doc as YDoc } from 'yjs';
import { Predicate } from '@property-folders/common/predicate';
import { useMemo } from 'react';
import { FormUtil } from '@property-folders/common/util/form';

export interface FormLineageRootKeyResult {
  data: string;
  meta: string;
  form?: FormInstance;
}

export function useFormLineageRootKey(formId: string | undefined, ydoc: YDoc): FormLineageRootKeyResult {
  const {
    bindState: masterMetaBindState
  } = useImmerYjs<TransactionMetaData>(
    ydoc,
    // We're still discovering the currently active root key, so we refer to the root's meta
    PropertyRootKey.Meta
  );
  const { data: alternateRootKeys } = masterMetaBindState(m => m.sublineageRoots ?? []);
  const { data: formIdList } = masterMetaBindState(m => listAvailableFormIds(m) ?? []);

  return useMemo<FormLineageRootKeyResult>(() => {
    const defaultResult: FormLineageRootKeyResult = {
      data: PropertyRootKey.Data, // Default values
      meta: PropertyRootKey.Meta
    };
    if (!formId) {
      return defaultResult;
    }

    const instance = findFormInstance(formId, undefined, ydoc, PropertyRootKey.Meta);
    if (instance) {
      return {
        data: PropertyRootKey.Data,
        meta: PropertyRootKey.Meta,
        form: instance
      };
    }

    for (const altRootKey of alternateRootKeys ?? []) {
      const currentMetaKey = altRootKey+META_APPEND;
      const instance = findFormInstance(formId, undefined, ydoc, currentMetaKey);
      if (instance) {
        return {
          data: altRootKey,
          meta: currentMetaKey,
          form: instance
        };
      }
    }
    return defaultResult;
  }, [formId, JSON.stringify(formIdList), JSON.stringify(alternateRootKeys)]);
}

function findFormInstance(formId: string, formCodeHint: FormCodeUnion | undefined, ydoc: YDoc, metaKey: string) {
  const meta = ydoc.getMap(metaKey).toJSON() as TransactionMetaData | undefined;
  if (!meta) return undefined;

  if (formCodeHint) {
    const hinted = FormUtil.getFormState(formCodeHint, formId, meta);
    if (hinted) {
      return hinted;
    }
  }

  return FormUtil.getFormStateFromIdAlone(formId, meta);
}

function listAvailableFormIds(meta: TransactionMetaData | undefined) {
  return listAvailableFormInstances(meta).map(fi => fi.id);
}

function listAvailableFormInstances(meta: TransactionMetaData | undefined) {
  if (!meta?.formStates) return [];
  if (typeof meta.formStates !== 'object') return [];
  return Object.values(meta.formStates)
    .flatMap(formState => formState.instances || [])
    .filter(Predicate.isTruthy);
}
