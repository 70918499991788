import './EpfOrderForm1.scss';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import React, { useEffect, useMemo, useState } from 'react';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import {
  PostRequestJobQuoteRequestBody,
  PostRequestJobQuoteResponse,
  PostRequestJobRequestBody,
  PostRequestJobResponseBody
} from '@property-folders/contract/rest/property';
import { uuidv4 } from 'lib0/random';
import * as Y from 'yjs';
import {
  FormTypes,
  PropertyFormYjsDal
} from '@property-folders/common/yjs-schema/property/form';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { Predicate } from '@property-folders/common/predicate';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { VendorWizardStepPage } from '../Wizard/VendorWizardStepPage';
import { useForm } from '../../hooks/useForm';
import { useSelector } from 'react-redux';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { cancelFormOrder } from '@property-folders/common/util/form';
import { useIsSailisOutage } from '@property-folders/web/src/redux/useIsSailisOutage';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { getContactsFromAuthorityParty } from '@property-folders/common/util/digest-authority-party-details';

export function EpfOrderForm1({
  yDoc,
  formId,
  formCode,
  transId
}: {
  yDoc: Y.Doc,
  transId: string,
  formId: string,
  formCode: string
}) {
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ 'myPath': '' });
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const headlineVal = generateHeadlineFromMaterialisedData(transRoot, narrowMode);
  const label = FormTypes[formCode].label;
  const contentTitle = `Order ${label}`;
  const breadcrumbs = useMemo(() => [
    { label: 'Properties', href: '/properties/' },
    {
      label: headlineVal || 'Property Overview',
      href: `/properties/${LinkBuilder.seoFriendlySlug(transId, headlineVal)}`
    },
    { label: contentTitle }
  ], [headlineVal]);
  const navigate = useNavigate();
  const [whoCompletesQuestionnaire, setWhoCompletesQuestionnaire] = useState<WhoCompletes>('EckermannPropertyForms');
  const [whoGetsSigning, setWhoGetsSigning] = useState<WhoGets>('EckermannPropertyForms');
  const [whoPays, setWhoPays] = useState<WhoPays>('Vendor');
  const [whoServes, setWhoServes] = useState<WhoServes>('Agency');
  const [apiResponse, setApiResponse] = useState<PostRequestJobQuoteResponse | undefined>(undefined);
  const [quoteError, setQuoteError] = useState(false);
  const [jobError, setJobError] = useState(false);
  const [apiRequest, setApiRequest] = useState('');
  const [showConfirmInstant, setShowConfirmInstant] = useState(false);
  const [showConfirmBasicIfInstantAvailable, setShowConfirmBasicIfInstantAvailable] = useState(false);

  const [isOrderingBasic, setIsOrderingBasic] = useState(false);
  const [isOrderingInstant, setIsOrderingInstant] = useState(false);

  const isOrdering = isOrderingBasic || isOrderingInstant;
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { docName, transactionRootKey, formName } = useForm();
  const primaryVendor = transRoot?.vendors?.find(v => v.id === transRoot?.primaryVendor);
  const [vendorHasBeenInvalid, setVendorHasbeenInvalid] = useState<boolean>(false);
  const isVendorValid = useSelector((state: any) => {
    const errorPathTree = state?.validation?.errorPaths?.[docName ?? '']?.[transactionRootKey ?? '']?.[formName];

    const { email: primaryVendorEmail } = getContactsFromAuthorityParty(primaryVendor, transRoot?.primaryVendor).filter(p=>p.isPrimary)[0];
    const validDataAllowsAutomatic = (primaryVendor?.addressSingleLine || primaryVendor?.addrSameAsSale) && primaryVendorEmail;

    return validDataAllowsAutomatic
      // We only want to say the vendor is not valid if we positively determine an issue.
      && (errorPathTree ? !Object.keys(errorPathTree).length : true);
  });
  const isSailisOutage = useIsSailisOutage();

  // we only will say that a vendor has been invalid after vendors have been loaded
  if (primaryVendor?.fullLegalName && !isVendorValid && !vendorHasBeenInvalid) setVendorHasbeenInvalid(true);

  useEffect(() => {
    const abort = new AbortController();
    const signal = abort.signal;
    const requestBody: PostRequestJobQuoteRequestBody = {
      source: 'epf',
      formId
    };
    WrappedFetch.json<PostRequestJobQuoteResponse>(LinkBuilder.restApi(`/properties/${transId}/job-quote-requests`), {
      method: 'POST',
      body: JSON.stringify(requestBody),
      signal
    })
      .then(result => {
        if (signal.aborted) return;
        setQuoteError(false);
        setApiResponse(result);
      })
      .catch(err => {
        console.error(err);
        if (signal.aborted) return;
        setQuoteError(true);
        setApiResponse(undefined);
      });

    return () => abort.abort();
  }, [transId, formId, apiRequest /*, titles, addresses, etc.*/]);

  useEffect(() => {
    if (apiResponse?.vendorPaidAdvertisingProvider) {
      setWhoPays('Provider');
    } else {
      setWhoPays(transRoot?.form1AndSearches?.whoPays === 'agent' ? 'Agency' : 'Vendor');
    }
  }, [apiResponse?.vendorPaidAdvertisingProvider]);

  const orderHandler = async (instant: boolean) => {
    setShowConfirmBasicIfInstantAvailable(false);
    setShowConfirmInstant(false);
    setJobError(false);
    if (instant) {
      setIsOrderingInstant(true);
    } else {
      setIsOrderingBasic(true);
    }

    try {
      const result = await doOrder(
        transId,
        {
          source: 'epf',
          formId: formId,
          formCode: formCode,
          formSpecific: {
            type: 'epf-form1',
            useInstantSearches: instant,
            whoPays,
            whoServes,
            whoCompletes: whoCompletesQuestionnaire,
            whoGets: whoGetsSigning
          }
        },
        new PropertyFormYjsDal(yDoc, PropertyRootKey.Data, PropertyRootKey.Meta), sessionInfo?.email||''
      );

      if (!result) {
        setJobError(true);
        return;
      }

    } finally {
      setIsOrderingBasic(false);
      setIsOrderingInstant(false);
    }
  };
  const orderBasic = () => orderHandler(false);
  const orderInstant = () => orderHandler(true);

  const handleCancelForm1Order = () => {
    cancelFormOrder(yDoc, formCode, formId);
    navigate(LinkBuilder.propertyPath({ id: transId, nicetext: headlineVal }));
  };

  const afterTitle = <>
    <Button variant={'outline-secondary'} onClick={handleCancelForm1Order}>Cancel</Button>
    <SpinnerButton
      variant={'primary'}
      disabled={!apiResponse || isOrdering || !isVendorValid}
      processing={isOrderingBasic}
      onClick={() => {
        if (apiResponse?.allowInstant && !isSailisOutage) {
          setShowConfirmBasicIfInstantAvailable(true);
          return;
        }
        orderBasic();
      }}
    >
      Order
    </SpinnerButton>
    {apiResponse?.allowInstant && !isSailisOutage && <SpinnerButton
      variant='primary'
      disabled={isOrdering || !isVendorValid}
      onClick={() => setShowConfirmInstant(true)}
      processing={isOrderingInstant}
    >
      Order with Instant Searches
    </SpinnerButton>}
  </>;

  return <div className='epf-order-form-1 h-100'>
    {showConfirmBasicIfInstantAvailable && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showConfirmBasicIfInstantAvailable} onClose={() => setShowConfirmBasicIfInstantAvailable(false)} />}>
      <EpfOrderBasicModal
        onHide={() => setShowConfirmBasicIfInstantAvailable(false)}
        onOrder={orderBasic}
        onShowInstant={()=>{setShowConfirmBasicIfInstantAvailable(false);setShowConfirmInstant(true);}}
        isOrdering={isOrdering}
        isOrderingBasic={isOrderingBasic}
        isSailisOutage={!!isSailisOutage}
        show={showConfirmBasicIfInstantAvailable}
      />
    </ErrorBoundary>}

    {showConfirmInstant && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showConfirmInstant} onClose={() => setShowConfirmInstant(false)} />}>
      <EpfInstantOrderModal
        show={showConfirmInstant}
        onHide={() => setShowConfirmInstant(false)}
        onOrder={orderInstant}
        isOrdering={isOrdering}
        isOrderingBasic={isOrderingBasic}
        propertyData={transRoot}
      />
    </ErrorBoundary>}
    <ContentTitler title={contentTitle} breadcrumbs={breadcrumbs} afterTitle={afterTitle}>
      <div style={{
        overflow: 'auto',
        height: 'calc(100% - 87px)'
      }}>
        <form
          autoComplete='off'
          noValidate style={{
            maxWidth: '800px',
            marginInline: 'auto'
          }}>
          <WizardStepPage
            name='Order Details'
            label='Order Details'
            icon='home'
          >
            {quoteError && <Alert variant='danger'>
              <p className='text-center'>There was an unexpected error trying to retrieve a quote for this form.</p>
              <p className='text-center'><Button onClick={() => {
                setQuoteError(false);
                setApiRequest(uuidv4());
              }}>Try Again</Button></p>
            </Alert>}
            {jobError && <Alert variant='danger'>
              <p className='text-center'>There was an unexpected error trying to create a job for this form. Please try again.</p>
            </Alert>}
            {!!apiResponse?.duplicates?.length && <Alert variant='warning'>
              <p className='text-center'>
            We have detected {apiResponse.duplicates.length === 1 ? 'an existing job' : 'existing jobs'} from
            your agency with the same titles on {apiResponse.duplicates.length === 1 ? 'it' : 'them'}.
            Proceeding further may incur irreversible fees when ordering duplicate searches from Land Services SA.
              </p>
              <p className='text-center'>
                {apiResponse.duplicates.length === 1 && <>
              You can view this job here: <DuplicateJobLink {...apiResponse.duplicates[0]} />
                </>}
              </p>
              {apiResponse.duplicates.length > 1 && <>
                <p className='text-center'>You can view these jobs here:</p>
                <ul style={{ width: 'max-content', marginInline: 'auto' }}>
                  {apiResponse.duplicates.map((dupe, index) => (<li key={index}>
                    <DuplicateJobLink {...dupe} />
                  </li>))}
                </ul>
              </>}
            </Alert>}
            <section className={'d-flex flex-column align-items-center m-auto mt-3'} style={{ maxWidth: '80ch' }}>
            </section>
            <section>
              <Row>
                <Col xs={12} className={'mx-auto w-100'}>
                  <div className={'w-50 mx-auto'}>
                    <img
                      src={LinkBuilder.propertiesImages('epf_logo.png')}
                      className={'w-100'}
                      alt='Eckermann Property Forms' />
                  </div>
                </Col>
              </Row>
              <Row>
                {apiResponse
                  ? <Col xs={12} lg={12} className='mx-auto mt-4'>
                    <table className='table table-borderless'>
                      <thead>
                        <tr style={{ borderBottomWidth: '1px' }}>
                          <th className=''>Item</th>
                          <th className=' text-end' style={{ minWidth: '8ch' }}>Price</th>
                          <th className=' text-end' style={{ minWidth: '2ch' }}>Qty</th>
                          <th className=' text-end' style={{ minWidth: '8ch' }}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {apiResponse.fees.map((item, index) =>
                          (<tr key={index}>
                            <td className=''>{item.label}:</td>
                            <td className='text-end'>{item.value}</td>
                            <td className='text-end'>{item.quantity}</td>
                            <td className='text-end'>{item.total}</td>
                          </tr>))}
                      </tbody>
                      <tfoot>
                        <tr style={{ borderTopWidth: '1px' }}>
                          <th colSpan={2} className=''>Estimated Price:</th>
                          <th colSpan={2} className='text-end'>{apiResponse.total}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </Col>
                  : !quoteError
                    ? <Col sm={6} className='d-flex flex-column justify-content-center align-items-center mx-auto'>
                      <Spinner animation='border' style={{ width: '4rem', height: '4rem' }} />
                      <span className='mt-2 fs-5'>Estimating price for this property transaction...</span>
                    </Col>
                    : <></>}
              </Row>
            </section>
            {/*<section className='mt-4'>*/}
            {/*  <Row>*/}
            {/*    <Col xs={12} className={'mx-lg-auto'}>*/}
            {/*      <h4>Customise the steps</h4>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*  <Row>*/}
            {/*    <Col xs={12} className={'mx-lg-auto'}>*/}
            {/*      <CustomiseRow<WhoCompletes>*/}
            {/*        key='who_completes'*/}
            {/*        label="Who is to complete the Vendor’s Questionnaire?"*/}
            {/*        name='who_completes'*/}
            {/*        currentValue={whoCompletesQuestionnaire}*/}
            {/*        onChoice={setWhoCompletesQuestionnaire}*/}
            {/*        choices={whoCompletesOptions} />*/}
            {/*      <hr />*/}
            {/*      <CustomiseRow<WhoGets>*/}
            {/*        key='who_signs'*/}
            {/*        label='Who is certifying the Form 1 and arranging Vendor signing?'*/}
            {/*        name='who_signs'*/}
            {/*        currentValue={whoGetsSigning}*/}
            {/*        onChoice={setWhoGetsSigning}*/}
            {/*        choices={whoGetsOptions} />*/}
            {/*      <hr />*/}
            {/*      <CustomiseRow<WhoPays>*/}
            {/*        key='who_pays'*/}
            {/*        label='Who is to pay for the searches and Form 1?'*/}
            {/*        name='who_pays'*/}
            {/*        currentValue={whoPays}*/}
            {/*        onChoice={setWhoPays}*/}
            {/*        choices={whoPaysOptions} />*/}
            {/*      <hr />*/}
            {/*      <CustomiseRow<WhoServes>*/}
            {/*        key='who_serves'*/}
            {/*        label='Who is to serve the Form 1 on the Purchaser?'*/}
            {/*        name='who_serves'*/}
            {/*        currentValue={whoServes}*/}
            {/*        onChoice={setWhoServes}*/}
            {/*        choices={whoServesOptions} />*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*</section>*/}
          </WizardStepPage>

          <div className={vendorHasBeenInvalid ? '':'d-none'} {/* By making this always render, we implicitly run validation. Otherwise invalid data may exist in the datamodel that won't be checked on page load*/...{}}>
            <VendorWizardStepPage
              topContent={!isVendorValid
                ? <Alert variant='danger'>Ordering a Form 1 requires a valid email and postal address for the Primary Vendor, please enter one below.</Alert>
                : <Alert variant='success'>The Vendor details are now filled.</Alert>
              }
            />
          </div>
        </form>
      </div>
    </ContentTitler>
  </div>;
}

export function EpfOrderBasicModal ({ show, onHide, isSailisOutage, isOrdering, isOrderingBasic, onOrder, onShowInstant }: {
  show: boolean,
  onHide: ()=>void,
  isSailisOutage: boolean,
  isOrdering: boolean,
  isOrderingBasic: boolean,
  onOrder: ()=>void,
  onShowInstant: ()=>void
}) {
  return <Modal show={show} size='lg' onHide={onHide}>
    <Modal.Header closeButton><Modal.Title>This job is eligible for Instant Searches</Modal.Title></Modal.Header>
    <Modal.Body>
      <p>
      Eckermann Property Forms has advanced real-time capabilities
      that enable it to instantly order searches and
      share those with you as soon as they are received.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant='outline-secondary'
        onClick={onHide}
      >Cancel</Button>
      <SpinnerButton
        variant='outline-secondary'
        onClick={onOrder}
        disabled={isOrdering}
        processing={isOrderingBasic}
      >Order</SpinnerButton>
      {!isSailisOutage && <Button onClick={onShowInstant}>Order with Instant Searches</Button>}
    </Modal.Footer>
  </Modal>;
}

function EpfInstantOrderModal({ propertyData: transRoot, show, onHide, isOrdering, isOrderingBasic, onOrder }: {
  show: boolean
  onHide: ()=>void
  propertyData?: MaterialisedPropertyData
  isOrdering: boolean,
  isOrderingBasic: boolean,
  onOrder: ()=>void
}) {
  return <Modal show={show} size='lg'  onHide={onHide}>
    <Modal.Header closeButton><Modal.Title>Instant Search Confirmation</Modal.Title></Modal.Header>
    <Modal.Body>
      <p>You have selected the Instant Searches option. When you submit this job, we will automatically order <b>{1} Property Interest Report</b> product from Land Services SA, and make these documents available to you instantly.</p>
      <p>These documents will be ordered based on the address and title information that you have entered.</p>
      <p>The Land Services SA charges cannot be reversed.</p>
      <p>
      Please confirm that the address and titles for this job are correct:
      </p>
      <ul>
        {(transRoot?.saleAddrs || []).map(addr => (<li key={addr.id}><b>{addr.streetAddr}</b></li>))}
        {(transRoot?.saleTitles || []).map(title => (<li key={title.id}><b>{title.title}</b></li>))}
      </ul>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onHide}>Cancel</Button>
      <SpinnerButton
        onClick={onOrder}
        disabled={isOrdering}
        processing={isOrderingBasic}
      >Confirm and order with Instant Searches</SpinnerButton>
    </Modal.Footer>
  </Modal>;
}

type CustomiseRowChoice<TOptionValue> = ({ value: TOptionValue, label: string } | undefined);

function CustomiseRow<TOptionValue extends string>({
  label,
  name,
  currentValue,
  choices,
  onChoice
}: {
  label: string,
  name: string,
  currentValue: TOptionValue,
  choices: CustomiseRowChoice<TOptionValue>[],
  onChoice: (value: TOptionValue) => void
}) {
  return <React.Fragment key={name}>
    <Row>
      <Col lg={7} className='d-flex align-items-center'>
        <Form.Label className='mb-0' htmlFor={name}>{label}</Form.Label>
      </Col>
      <Col lg={5}>
        <Form.Select defaultValue={currentValue} onChange={e => onChoice(e.target.value as TOptionValue)}>
          {choices.filter(Predicate.isTruthy).map((o, index) => (
            <option key={index} value={o.value}>{o.label}</option>
          ))}
        </Form.Select>
      </Col>
    </Row>
  </React.Fragment>;
}

function DuplicateJobLink({
  jobId,
  address,
  propertyFolderId,
  formId
}: {
  jobId: number,
  address: string,
  propertyFolderId?: string,
  formId?: string
}) {
  const navigate = useNavigate();
  const location = propertyFolderId && formId
    ? `${LinkBuilder.documentPath({ id: propertyFolderId }, { id: formId }, true)}?view_order=1`
    : `/form1_dashboard.php?page=view&jobId=${jobId}`;

  return <Alert.Link
    href={location}
    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      e.stopPropagation();
      navigate(location);
    }}>{address}</Alert.Link>;
}

async function doOrder(propertyId: string, requestBody: PostRequestJobRequestBody, dal: PropertyFormYjsDal, notificationEmail: string) {
  try {
    const result = await WrappedFetch.json<PostRequestJobResponseBody>(LinkBuilder.restApi(`/properties/${propertyId}/job-requests`), {
      method: 'POST',
      body: JSON.stringify(requestBody)
    });

    if (!result) {
      console.error('No response');
      return undefined;
    }

    const { formCode, formId } = requestBody;
    dal.setFormOrdered({ formCode,
      formId,
      jobId: result.jobId,
      fillerManagedSigning: requestBody.formSpecific.whoGets === 'EckermannPropertyForms',
      orderInfo: {
        type: requestBody.formSpecific.type,
        whoCompletesVq: requestBody.formSpecific.whoCompletes,
        whoPays: requestBody.formSpecific.whoPays,
        whoServes: requestBody.formSpecific.whoServes,
        useInstantSearches: requestBody.formSpecific.useInstantSearches ?? false,
        waitForSearches: requestBody.formSpecific.useInstantSearches ?? false,
        whoArrangesSigning: requestBody.formSpecific.whoGets
      },
      notificationEmail: notificationEmail
    });
  } catch (err: unknown) {
    console.error(err);
    return undefined;
  }
}

type WhoCompletes = PostRequestJobRequestBody['formSpecific']['whoCompletes'];
const whoCompletesOptions: CustomiseRowChoice<WhoCompletes>[] = [{
  value: 'EckermannPropertyForms',
  label: 'Eckermann Property Forms'
},{
  value: 'Vendor',
  label: 'Vendor'
},{
  value: 'SalesPerson',
  label: 'Agency'
}];

type WhoGets = PostRequestJobRequestBody['formSpecific']['whoGets'];
const whoGetsOptions: CustomiseRowChoice<WhoGets>[] = [{
  value: 'EckermannPropertyForms',
  label: 'Eckermann Property Forms'
},
undefined,
{
  value: 'SalesPerson',
  label: 'Agency'
}];

type WhoPays = PostRequestJobRequestBody['formSpecific']['whoPays'];
function getWhoPaysOptions(vendorPaidAdvertisingProvider: string | undefined): CustomiseRowChoice<WhoPays>[] {
  return vendorPaidAdvertisingProvider
    ? [{
      value: 'Provider',
      label: `Vendor (${vendorPaidAdvertisingProvider})`
    },{
      value: 'Vendor',
      label: 'Vendor (Direct)'
    },{
      value: 'Agency',
      label: 'Agency'
    }]
    : [{
      value: 'Vendor',
      label: 'Vendor'
    },
    undefined,
    {
      value: 'Agency',
      label: 'Agency'
    }];
}

type WhoServes = PostRequestJobRequestBody['formSpecific']['whoServes'];
const whoServesOptions: CustomiseRowChoice<WhoServes>[] = [
  undefined,
  undefined,
  {
    value: 'Agency',
    label: 'Agency'
  }];
