import './SharingWizardStepPage.scss';

import { WizardStepPage, WizardStepPageProps } from './WizardStepPage';
import { useOnline } from '../../hooks/useOnline';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { YjsDocContext } from '../../context/YjsDocContext';
import { FormContext } from '../../context/FormContext';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { GetPropertyAccessPermissionsResult } from '@property-folders/contract/rest/property';
import {
  Agent,
  LookupCanShareResultItem,
  Maybe, OwningEntity,
  TransactionMetaData,
  TransactionSharing, TransactionSharingExcludeItem,
  TransactionSharingQueueItem
} from '@property-folders/contract';
import { byManyAspects, byMapperFn } from '@property-folders/common/util/sortComparison';
import { CollectionRemoveButton } from '../form/CollectionRemoveButton';
import { v4 } from 'uuid';
import { Col, Row, Spinner } from 'react-bootstrap';
import { Lookups } from '@property-folders/common/client-api/lookups';
import Select, { createFilter } from 'react-select';
import { Avatar } from '../Avatar';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { Predicate } from '@property-folders/common/predicate';

export function SharingWizardStepPage(props: Partial<WizardStepPageProps>) {
  const online = useOnline();
  const { formName } = useContext(FormContext);
  const creating = formName === 'newTrans';
  const { value: agent } = useLightweightTransaction<Agent[]>({ myPath: 'agent' });
  const sharingChangesPending = Boolean(agent?.some(a => {
    return a.sharingChangesPending
      || a.salesp?.some(sp => sp.sharingChangesPending);
  }));

  return online ? <WizardStepPage
    name='sharing'
    icon='sharing'
    {...props}
    label={sharingChangesPending
      ? <div className='d-flex flex-column'>Sharing <small className='text-muted fs-6 fw-normal'>Server changes pending <Spinner size='sm' animation='border' /></small></div>
      : 'Sharing'}
  >
    {creating
      ? <CreatingSharingView />
      : <EditSharingView serverPending={sharingChangesPending}/>}
  </WizardStepPage> : <></>;
}

export function EditSharingView({ serverPending }:{ serverPending: boolean }) {
  const { docName: propertyId } = useContext(YjsDocContext);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState<Maybe<string>>(undefined);
  const [data, setData] = useState<Maybe<GetPropertyAccessPermissionsResult>>(undefined);
  const { value: sharing } = useTransactionField<TransactionSharing>({ myPath: 'sharing', bindToMetaKey: true });
  const { value: entity } = useTransactionField<OwningEntity>({ myPath: 'entity', bindToMetaKey: true });

  useEffect(() => {
    if (serverPending) return;
    setLoading(true);
  }, [serverPending]);

  useEffect(() => {
    if (!propertyId) return;
    const ac = new AbortController();
    WrappedFetch.json<GetPropertyAccessPermissionsResult>(LinkBuilder.restApi(`/properties/${propertyId}/access-permissions`), { signal: ac.signal })
      .then(res => {
        if (ac.signal.aborted) return;
        if (res?.issues?.length) console.warn(res.issues);
        setData(res);
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (ac.signal.aborted) return;
        setLoading(false);
      });

    return () => {
      ac.abort();
    };
  }, [propertyId, reload, sharing?.lastSync]);

  const { display, existingSids } = useMemo(() => {
    const display = mapPropertyAccessResultToMixedItems(data)
      .sort(byManyAspects([
        byMapperFn(x => x.deletable ? 1 : 0),
        (a, b) => a.headline.localeCompare(b.headline)
      ]));

    return { display, existingSids: new Set<number>(display.map(d => d.sid)) };
  }, [data]);

  return <div className='d-flex flex-column gap-4'>
    {!display.length && (loading || sharing?.creating) &&
      <div className='d-flex flex-row justify-content-center'><Spinner animation='border'/></div>}
    {display.map((item, index) => {
      return <SharingRecipientRow
        key={index}
        item={item}
        canEdit={Boolean(data?.canEdit)}
        onChangeCanShare={value => WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/access-permissions/${item.accessId}?canShare=${value}`), { method: 'POST' })
          .catch(err => console.error(err))
          .finally(() => setReload(v4()))}
        onRemove={() => {
          WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/access-permissions/${item.accessId}`), { method: 'DELETE' })
            .catch(err => console.error(err))
            .finally(() => setReload(v4()));
        }}
      />;
    })}
    {propertyId && data?.canEdit && entity && <Row>
      <Col xs={12} style={{ zIndex: '10' }}>
        <AddSharingRecipient
          existingSids={existingSids}
          entityId={entity.id}
          onAdd={item => {
            WrappedFetch.bare(LinkBuilder.restApi(`/properties/${propertyId}/access-permissions?type=${item.type}&id=${item.id}`), {
              method: 'PUT'
            })
              .then(() => setReload(v4()))
              .catch(console.error);
          }}
        />
      </Col>
    </Row>}
  </div>;
}

export function CreatingSharingView() {
  const { value: sharing } = useTransactionField<TransactionSharing>({ myPath: 'sharing', bindToMetaKey: true });
  const { value: entity } = useTransactionField<Maybe<OwningEntity>>({ myPath: 'entity', bindToMetaKey: true });
  const { value: agent } = useTransactionField<Maybe<Agent[]>>({ myPath: 'agent' });
  const { updateDraft: updateMeta } = useYdocBinder<TransactionMetaData>({ path: '', bindToMetaKey: true });
  const [ defaultShare, setDefaultShare ] = useState<MixedSharingItem[]>([]);
  const salesTeams = useMemo(() => {
    return new Set(defaultShare.filter(ds => ds.role === 'Sales Team').map(ds => ds.sid));
  }, [defaultShare]);

  useEffect(() => {
    if (sharing?.creating) return;
    updateMeta?.(draft => {
      if (!draft.sharing) {
        draft.sharing = {
          creating: true,
          createExclusions: [],
          createQueue: []
        };
      } else {
        draft.sharing.creating = true;
      }
    });
  }, [sharing?.creating, !!updateMeta]);

  const { display, existingSids } = useMemo(() => {
    const display = (sharing?.createQueue?.map<MixedSharingItem>(x => {
      return {
        accessId: x.sid * -1,
        sid: x.sid,
        role: x.entityId
          ? 'Agency'
          : x.teamId
            ? salesTeams.has(x.sid) ? 'Sales Team' : 'Team'
            : 'User',
        entityId: x.entityId,
        teamId: x.teamId,
        agentId: x.agentId,
        headline: x.headline,
        subtitle: x.subtitle || '',
        canShare: x.canShare,
        editable: true,
        deletable: true
      };
    }) ?? [])
      .concat(
        defaultShare.filter(ds => !sharing?.createQueue?.find(x => x.sid === ds.sid))
      )
      .filter(x => !sharing?.createExclusions?.find(ex => idsMatch(ex, x)))
      .sort(byManyAspects([
        byMapperFn(x => x.deletable ? 1 : 0),
        (a, b) => a.headline.localeCompare(b.headline)
      ]));
    return { display, existingSids: new Set<number>(display.map(d => d.sid)) };
  }, [sharing, defaultShare, salesTeams]);

  const salesps = agent
    ?.flatMap(a => a.salesp?.map(sp => sp.linkedSalespersonId) || [])
    ?.filter(Predicate.isNotNull) || [];
  useEffect(() => {
    if (!entity) return;
    const ac = new AbortController();
    WrappedFetch.json<GetPropertyAccessPermissionsResult>(LinkBuilder.restApi(`/properties/default/access-permissions?entityId=${entity.id}&salespIds=${salesps.join(',')}`), { signal: ac.signal })
      .then(res => {
        if (ac.signal.aborted) return;
        if (res?.issues?.length) console.warn(res.issues);
        setDefaultShare(mapPropertyAccessResultToMixedItems(res));
      })
      .catch(err => console.error(err));

    return () => {
      ac.abort();
    };
  }, [entity?.id, salesps.join(',')]);

  return <div className='d-flex flex-column gap-4'>
    {display.map((item, index)=> {
      return <SharingRecipientRow
        key={index}
        item={item}
        canEdit={true}
        onChangeCanShare={value => {
          updateMeta?.(draft => {
            const queue = ensureSharingQueue(draft);
            const match = queue.find(x => x.sid === item.sid);
            if (match) {
              match.canShare = value === 'true';
            } else {
              const matchNew = defaultShare.find(ds => ds.sid === item.sid);
              if (matchNew) {
                queue.push({
                  id: v4(),
                  sid: item.sid,
                  headline: matchNew.headline,
                  subtitle: matchNew.subtitle,
                  canShare: value === 'true',
                  entityId: matchNew.entityId,
                  agentId: matchNew.agentId,
                  teamId: matchNew.teamId
                });
              }
            }
          });
        }}
        onRemove={() => {
          updateMeta?.(draft => {
            const queue = ensureSharingQueue(draft);
            const exclusions = ensureSharingExclusions(draft);
            const idxQueue = queue.findIndex(sq => idsMatch(item, sq));
            if (idxQueue >= 0) {
              queue.splice(idxQueue, 1);
            }
            exclusions.push({
              id: v4(),
              sid: item.sid,
              entityId: item.entityId,
              agentId: item.agentId,
              teamId: item.teamId
            });
          });
        }}
      />;
    })}
    {entity && <Row>
      <Col xs={12} style={{ zIndex: '10' }}>
        <AddSharingRecipient
          existingSids={existingSids}
          entityId={entity.id}
          onAdd={item => {
            updateMeta?.(draft => {
              const queue = ensureSharingQueue(draft);
              const exclusions = ensureSharingExclusions(draft);
              const idxExclusion = exclusions?.findIndex(ex => idsMatchLookup(item, ex));
              if (idxExclusion >= 0) {
                exclusions.splice(idxExclusion, 1);
              }
              queue.push({
                id: v4(),
                sid: item.sid,
                headline: item.name,
                subtitle: item.subtitle || undefined,
                canShare: false,
                entityId: item.type === 'entity' ? item.id : undefined,
                agentId: item.type === 'agent' ? item.id : undefined,
                teamId: item.type === 'team' ? item.id : undefined
              });
            });
          }}
        />
      </Col>
    </Row>}
  </div>;
}

function AddSharingRecipient({
  existingSids,
  onAdd,
  entityId
}: {
  existingSids: Set<number>,
  onAdd: (item: LookupCanShareResultItem) => void,
  entityId: number
}) {
  const [options, setOptions] = useState<LookupCanShareResultItem[]>([]);

  useEffect(() => {
    const { ac, results } = Lookups.lookupCanShare(entityId);

    results
      .then(data => {
        if (ac.signal.aborted) return;
        setOptions(data?.items || []);
      })
      .catch(console.error);

    return () => {
      ac.abort();
    };
  }, [entityId]);

  const filtered = useMemo(() => {
    return options.filter(o => !existingSids.has(o.sid));
  }, [options, existingSids]);

  return <Select<LookupCanShareResultItem>
    options={filtered}
    filterOption={createFilter<LookupCanShareResultItem>({
      stringify: o => o.data.name
    })}

    isSearchable={true}
    menuPlacement={'top'}
    className='user-team-selector'
    placeholder={'Add a team or user'}
    onChange={item => {
      if (!item) return;
      onAdd(item);
    }}

    formatOptionLabel={(option, meta) => {
      return <Avatar
        name={option.name}
        agentId={option.type === 'agent' ? option.id : undefined}
        entityName={option.subtitle}
        teamId={option.type === 'team' ? option.id : undefined}
      />;
    }}
    value={[]}
    classNames={{
      option: () => 'option',
      multiValueLabel: () => 'label'
    }}
  />;
}

function SharingRecipientRow({
  item,
  canEdit,
  onRemove,
  onChangeCanShare
}: {
  item: MixedSharingItem,
  canEdit: boolean,
  onRemove: () => void,
  onChangeCanShare: (value: 'true' | 'false') => void,
}) {
  return <>
    <Row className='d-none d-md-flex flex-row justify-content-between py-2'>
      <Col xs={5}>
        <Avatar
          agentId={item.agentId}
          name={item.headline}
          entityName={item.subtitle || ''}
          teamId={item.teamId}
          entity={Boolean(item.entityId)}
          contents={{
            show: true,
            placement: 'top'
          }}
        />
      </Col>
      <Col xs={2} className={'d-flex align-items-center'}>
        <span>{item.role}</span>
      </Col>
      <Col xs={4} className={'d-flex align-items-center'}>
        <SelectCanShare
          value={item.canShare ? 'true' : 'false'}
          disabled={!(item.editable && canEdit)}
          onChange={v => onChangeCanShare(v)}
        />
      </Col>
      <Col xs={1} className={'d-flex justify-content-end'}>
        {item.deletable && canEdit && <CollectionRemoveButton
          onRemove={onRemove}
          removable={true}
          title={'Remove this item'}
        />}
      </Col>
    </Row>
    <div className={'d-flex d-md-none flex-column gap-3'}>
      <div className={'d-flex flex-row justify-content-between align-items-baseline'}>
        <Avatar
          agentId={item.agentId}
          name={item.headline}
          entityName={item.subtitle || ''}
          teamId={item.teamId}
          entity={Boolean(item.entityId)}
          contents={{
            show: true,
            placement: 'top'
          }}
        />
        {item.deletable && canEdit && <div><CollectionRemoveButton
          onRemove={onRemove}
          removable={true}
          title={'Remove this item'}
        /></div>}
      </div>
      <SelectCanShare
        value={item.canShare ? 'true' : 'false'}
        disabled={!(item.editable && canEdit)}
        onChange={v => onChangeCanShare(v)}
      />
      <hr className={'m-0'} />
    </div>
  </>;
}

const selectCanShareOptions = [
  { value: 'false', label: 'Can edit' },
  { value: 'true', label: 'Can edit and share' }
];
function SelectCanShare({ value, disabled, onChange }: { value: string, disabled: boolean, onChange: (value: 'true' | 'false') => void}) {
  return <Select
    className='w-100'
    value={selectCanShareOptions.find(x => x.value === value)}
    isDisabled={disabled}
    options={selectCanShareOptions}
    onChange={x => {
      if (!x?.value) return;
      onChange(x.value as 'true' | 'false');
    }}
    menuPlacement='top'
    styles={{
      menu: base => ({
        ...base,
        zIndex: 11
      })
    }}
  />;
}

interface MixedSharingItem {
  accessId: number;
  sid: number;
  role: string;
  entityId?: number;
  teamId?: number;
  agentId?: number;
  headline: string;
  subtitle?: string;
  canShare: boolean;
  editable: boolean;
  deletable: boolean;
}

function ensureSharingQueue(meta: TransactionMetaData): TransactionSharingQueueItem[] {
  if (!meta.sharing) {
    meta.sharing = {};
  }

  if (!meta.sharing.createQueue) {
    meta.sharing.createQueue = [];
  }

  return meta.sharing.createQueue;
}

function ensureSharingExclusions(meta: TransactionMetaData): TransactionSharingExcludeItem[] {
  if (!meta.sharing) {
    meta.sharing = {};
  }

  if (!meta.sharing.createExclusions) {
    meta.sharing.createExclusions = [];
  }

  return meta.sharing.createExclusions;
}

function mapPropertyAccessResultToMixedItems(data: Maybe<GetPropertyAccessPermissionsResult>): MixedSharingItem[] {
  return new Array<MixedSharingItem>()
    .concat(data?.teams?.map<MixedSharingItem>(x => {
      return {
        accessId: x.accessId,
        role: x.role,
        sid: x.sid,
        teamId: x.teamId,
        headline: x.teamName,
        subtitle: x.entityName,
        canShare: x.perms.share,
        editable: x.actions.editable,
        deletable: x.actions.deletable
      };
    }) || [])
    .concat(data?.agents?.map<MixedSharingItem>(x => {
      return {
        accessId: x.accessId,
        role: x.role,
        sid: x.sid,
        agentId: x.agentId,
        headline: x.agentName,
        canShare: x.perms.share,
        editable: x.actions.editable,
        deletable: x.actions.deletable
      };
    }) || []);
}

interface IdsMatchGenericParam { agentId?: number, teamId?: number, entityId?: number }
function idsMatchLookup(
  a: LookupCanShareResultItem,
  b: IdsMatchGenericParam
): boolean {
  if (a.type === 'agent') return a.id === b.agentId;
  if (a.type === 'team') return a.id === b.teamId;
  if (a.type === 'entity') return a.id === b.entityId;

  return false;
}

function idsMatch(
  a: IdsMatchGenericParam,
  b: IdsMatchGenericParam
): boolean {
  if (a.agentId) return a.agentId === b.agentId;
  if (a.teamId) return a.teamId === b.teamId;
  if (a.entityId) return a.entityId === b.entityId;

  return false;
}
