import { YDocContentType } from '@property-folders/contract';
import { EntitySettingsEntity, EntitySettingsRootKey } from '@property-folders/contract/yjs-schema/entity-settings';
import { useContext } from 'react';
import { YManagerContext } from '../context/YManagerContext';
import { useImmerYjs } from './useImmerYjs';

export function useEntityYDoc(entityUuid: string|undefined, rootKey: EntitySettingsRootKey = EntitySettingsRootKey.Main) {
  const { instance: yManager } = useContext(YManagerContext);
  const entityDoc = yManager?.get(entityUuid ?? '', YDocContentType.EntitySettings, { noCreate: true, readOnly: true }).doc;

  return useImmerYjs<EntitySettingsEntity>(entityDoc, rootKey);
}
