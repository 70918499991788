import { Alert, Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Icon } from './Icon';
import {
  API_CHARGE_PRICE_CENTS,
  API_CHARGE_PRICE_STR,
  LegacyApi,
  Payment,
  Searches,
  StoredCard
} from '@property-folders/common/client-api/legacyApi';
import { CardLogo, CardType } from './CardLogo';
import './PaymentModal.scss';
import { CardDetails } from './payment/CardDetails';

export interface LineItem {
  description: string;
  price: string;
  priceCents: number;
}

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
  searches: Searches | undefined;
  entityId: string | number;
  onPayment: (token: string) => void;
  formUrl?: string;
}

function calculateEwaySurcharge(cardType: CardType, priceCents: number) {
  const percentageTimes10 = cardType === CardType.AMEX ? 18 : 19;
  const flatFeeCents = cardType === CardType.AMEX ? 28 : 30;
  const variableChargeCents = Math.round(priceCents * percentageTimes10) / 1000;

  return flatFeeCents + variableChargeCents;
}

export function PaymentModal(props: Props) {
  const [storedPayment, setStoredPayment] = useState<StoredCard | false | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [makingPayment, setMakingPayment] = useState(false);
  const [cardValid, setCardValid] = useState<boolean>(false);
  const [cardDetails, setCardDetails] = useState<CardDetails|null>(null);

  const lineItems: LineItem[] = (props.searches ?? []).map(s => ({
    description: s.type === 'proprietor' ? `Proprietor Search (${s.address})` : `Title Search (${s.title} ${s.address})`,
    price: LegacyApi.paymentTypeToPrice(s.type).formatted,
    priceCents: LegacyApi.paymentTypeToPrice(s.type).cents
  })) ?? [];
  lineItems.push(
    ...(props.searches ?? [])
      .filter(s => s.type === 'title_pf')
      .map(s => ({
        description: `Land Services API Charge (${s.title})`,
        price: API_CHARGE_PRICE_STR,
        priceCents: API_CHARGE_PRICE_CENTS
      }))
  );
  const totalPrice = lineItems.reduce((ac, cur) => {
    if (!cur) {
      return ac;
    }

    return ac + Number(cur.priceCents);
  }, 0);

  const ewaySurchargeCents = Math.round(calculateEwaySurcharge(cardDetails?.cardType ?? CardType.UNKNOWN, totalPrice));
  lineItems.push({
    description: 'Eway Processing Fee',
    price: (ewaySurchargeCents / 100).toFixed(2),
    priceCents: ewaySurchargeCents
  });

  useEffect(() => {
    if (!props.show) {
      setStoredPayment(null);
      return;
    }

    LegacyApi.checkForStoredPayment(String(props.entityId)).then(card => {
      if (!card.available) {
        setStoredPayment(false);
        return;
      }

      setStoredPayment(card);
    });
  }, [props.show]);

  const makePayment = () => {
    setErrorText(null);

    if (!storedPayment && (!cardDetails || !cardValid)) {
      setErrorText('Please enter all required details');
      return;
    }

    if (!props.searches) {
      setErrorText('An error occurred, please try again');
      return;
    }

    const payment: Payment = {
      type: 'mixed',
      searches: props.searches,
      formUrl: props.formUrl || window.location.href
    };

    if (cardDetails && cardValid) {
      payment.card = {
        name: cardDetails.name,
        cvn: cardDetails.cvn,
        number: cardDetails.number,
        expiry_month: cardDetails.expiryMonth,
        expiry_year: cardDetails.expiryYear,
        cardType: cardDetails.cardType
      };
    }

    setMakingPayment(true);
    LegacyApi.makePayment(String(props.entityId), payment).then(token => {
      props.onPayment(token);
      props.setShow(false);
    }).catch(e => {
      setErrorText('An error occurred trying to process your payment');
    }).finally(() => {
      setMakingPayment(false);
    });
  };

  let modalBody = <>
    <Container className='px-4 mb-3'>
      {storedPayment !== null && !!storedPayment && <Row className='mt-4'>
        <div>

          <div className='d-flex align-items-center'>
            <CardLogo cardType={storedPayment.card.cardType} style={{ fontSize: '32px' }} />
            <h5 className='m-0 ms-2'>Stored Credit Card Details</h5>
          </div>

          <p>
            <strong>{storedPayment.card.name}</strong><br />
            xxxx xxxx xxxx {storedPayment.card.lastFour}<br />
            {storedPayment.card.expiry}
          </p>
        </div>
      </Row>}

      {storedPayment !== null && !storedPayment && <>
        <Row>
          <Col xs={12} lg={6}>
            <CardDetails
              setValue={setCardDetails}
              setValid={setCardValid}
            />
          </Col>
          <Col xs={12} lg={6}>
            <Container>
              <Row>
                <Col>
                  <h5>Register Credit Card</h5>
                  <p>Your business can register a credit card for future searches.</p>
                  <p>The <strong>Main User</strong> registers a card in:</p>
                  <p><strong>Agency Settings {'>'} Billing | Title Purchases</strong></p>
                  <p>This is for business wide use.</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </>}

      <Row><h5>Purchases</h5></Row>
      {(lineItems ?? []).map(li => <Row>
        <Col>
          <div className='d-flex'>
            <span>{li.description}</span>
            <span className='ms-auto'>${li.price}</span>
          </div>
        </Col>
      </Row>)}

    </Container>
  </>;

  if (storedPayment === null) {
    modalBody = <div>Loading...</div>;
  }

  return <Modal
    size='lg'
    show={props.show}
    onHide={() => {
      props.setShow(false);
    }}
    centered={true}
  >
    <Modal.Header>
      <h4>Payment Details</h4>
    </Modal.Header>
    <Modal.Body className='PaymentsModal'>
      {errorText && <Alert variant='danger' className='mt-3'>{errorText}</Alert>}

      {modalBody}

      <div className='d-flex'>
        <Button
          onClick={makePayment}
          disabled={storedPayment === null || makingPayment || (storedPayment === false && !cardValid)}
          className='mt-3 ms-auto'
        >
          Pay Now <Icon name='check' pack='material-symbols' />
        </Button>
      </div>
    </Modal.Body>
  </Modal>;
}
