import './PartyConfigurationCard.scss';
import { Maybe } from '@property-folders/common/types/Utility';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import {
  AgencySalesperson,
  AuthorityParty,
  FormCode,
  FormCodeUnion,
  FormInstanceSigning,
  MaterialisedPropertyData,
  SaleMethod,
  SignerProxyAuthorityOptions,
  SignerProxyType,
  SigningInitiator,
  SigningParty,
  SigningPartyMessageConfig,
  SigningPartySnapshot,
  SigningPartySourceType,
  SigningPartySourceVendors,
  SigningPartyType,
  SigningPartyTypeOptions,
  SigningPartyVerificationConfig,
  SigningPartyVerificationType,
  ValidAuctioneerSigners,
  jointTypes,
  sourceTypeGroup
} from '@property-folders/contract';
import { Predicate } from '@property-folders/common/predicate';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { buildFilteredSigningPartyTypeOptions, IPartyDetailPaths } from './Common';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { Accordion, Button, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import React, { memo, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { CustomiseVerification } from '@property-folders/components/dragged-components/signing/CustomiseVerification';
import { CustomiseMessage } from '@property-folders/components/dragged-components/signing/CustomiseMessage';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { EntitySettingsSalesperson, EntitySettingsSigningOptions } from '@property-folders/contract/yjs-schema/entity-settings';
import clsJn from '@property-folders/common/util/classNameJoin';
import { WizardDisplayContext } from '@property-folders/components/context/WizardContexts';
import { FormatBI } from '../../display/FormatBI';
import { useForm } from '../../hooks/useForm';
import { useSelector } from 'react-redux';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { usePrevious } from 'react-use';
import { parseInt2 } from '@property-folders/common/util/formatting';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { generateParentPath, getValueByPath, mergePaths, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { applySplitPartySignerType } from '@property-folders/common/yjs-schema/property/validation/field-group-functions/splitPartySignerType';
import { uniq } from 'lodash';
import { useCurrentEntity } from '../../hooks/useEntity';

export function configAllowsVerification(signingParty: Maybe<Pick<SigningParty, 'type' | 'source'>>): boolean {
  if (!signingParty) {
    return false;
  }

  const signingPartySourceType = signingParty.source?.overrideType || signingParty.source?.type;

  return signingParty.type !== SigningPartyType.SignWet
    && signingParty.type !== SigningPartyType.SignOnlineSms
    && signingPartySourceType !== SigningPartySourceType.Salesperson;
}

export function configAllowsMessage(signingParty: Maybe<Pick<SigningParty, 'type'>>): boolean {
  if (!signingParty) {
    return false;
  }

  return signingParty.type === SigningPartyType.SignOnline;
}

export function CustomiseItemWrapper(props: {
  eventKey: string,
  title: string,
  description?: string,
  icon: string,
  canDelete: boolean,
  onDeleteClick?: () => void,
  children: ReactNode,
  noCollapse?: boolean
}) {
  return <Accordion.Item eventKey={props.eventKey}>
    <Accordion.Header className={clsJn(props.noCollapse && 'no-collapse')}>
      <div className={'d-flex w-100 pe-3 flex-row justify-content-between align-items-center'}>
        <div className={'d-flex flex-row align-items-center'}>
          <Icon name={props.icon}></Icon>
          <div className={'ms-2 d-flex flex-column'}>
            <span>{props.title}</span>
            {props.description && <small>{props.description}</small>}
          </div>
        </div>
        {props.canDelete &&
          <Button as={'div'} variant={''} className={''} onClick={props.onDeleteClick}>
            <i className="bi-trash-fill"></i>
          </Button>
        }
      </div>
    </Accordion.Header>
    <Accordion.Body>
      {props.children}
    </Accordion.Body>
  </Accordion.Item>;
}

function CustomiseDropdownItem(props: { onClick: () => void, label: string, description: string, icon: string }) {
  return <Dropdown.Item onClick={props.onClick} className={'d-flex flex-row align-items-center'}>
    <Icon name={props.icon}></Icon>
    <div className={'d-flex flex-column ms-2'}>
      <span>{props.label}</span>
      <small>{props.description}</small>
    </div>
  </Dropdown.Item>;
}

export function buildCustomiseDropdownItems({
  isOnline,
  verificationConfig,
  messageConfig,
  signingParty,
  updateVerificationConfig,
  updateMessageConfig,
  setPartyProxy,
  portal,
  formCode,
  saleMethod
}: {
  isOnline: boolean,
  verificationConfig?: SigningPartyVerificationConfig,
  messageConfig?: SigningPartyMessageConfig,
  signingParty?: Pick<SigningParty, 'type' | 'source' | 'proxyAuthority'>,
  updateVerificationConfig: (config: SigningPartyVerificationConfig) => void,
  updateMessageConfig: (config: SigningPartyMessageConfig) => void,
  setPartyProxy?: (repType: SignerProxyType) => void,
  portal?: boolean,
  formCode?: FormCodeUnion,
  saleMethod?: SaleMethod
}) {
  const allowOnlineVerification = (isOnline || signingParty?.type === SigningPartyType.SignOnline);
  const allow = {
    verification: configAllowsVerification(signingParty),
    message: configAllowsMessage(signingParty) && !portal
  };
  const show = {
    dropdown: {
      verification: !verificationConfig && allow.verification && allowOnlineVerification,
      message: !messageConfig && allow.message
    }
  };

  const partyType = signingParty?.source.overrideType || signingParty?.source.type;
  const proxyAllowed = !portal && setPartyProxy && formCode && FormTypes[formCode].formFamily !== FormCode.RSAA_SalesAgencyAgreement && !Predicate.proxyNotSelf(signingParty?.proxyAuthority);

  const allowSalespersonAsRep = proxyAllowed && SigningPartySourceVendors.includes(partyType);

  // Auctioneers shall only be allowed to sign on the primary contract document
  const allowAuctioneerAsRep = proxyAllowed && formCode === FormCode.RSC_ContractOfSale && saleMethod === SaleMethod.Auction && ValidAuctioneerSigners.includes(partyType);

  return {
    allow,
    items: [

      allowAuctioneerAsRep && <CustomiseDropdownItem
        key={1}
        label='Sign as Auctioneer'
        description='The auctioneer may sign a Contract on behalf of a Purchaser or a Vendor'
        icon='gavel'
        onClick={()=>setPartyProxy(SignerProxyType.Auctioneer)}
      />,
      allowSalespersonAsRep && <CustomiseDropdownItem
        key={2}
        label='Sign as Salesperson'
        description={'The Salesperson is authorised to sign any documents on the Vendor\'s behalf'}
        icon='person'
        onClick={()=>setPartyProxy(SignerProxyType.Salesperson)}
      />,
      show.dropdown.verification && <CustomiseDropdownItem
        key={3}
        onClick={() => {
          updateVerificationConfig({
            type: SigningPartyVerificationType.Sms
          });
        }}
        label='Two-Factor Authentication'
        description={'Make sure the right person accesses the document'}
        icon={'badge'}></CustomiseDropdownItem>,
      show.dropdown.message && <CustomiseDropdownItem
        key={4}
        onClick={() => {
          updateMessageConfig({
            content: ''
          });
        }}
        label={'Message'}
        description={'Set a personal message for this recipient'}
        icon={'chat_bubble'}></CustomiseDropdownItem>

    ].filter(Predicate.isTruthy)
  };
}

const AccordionKey = {
  SignAsAuctioneer: '1',
  SignAsSalesperson: '2',
  IdentityVerification: '3',
  Message: '4'
};

export function PartyConfigurationCard({
  partiallySignedConfiguration,
  paths,
  id,
  initiator,
  salespersons,
  signingOptions,
  removedData,
  disabled,
  noUndefinedSalesperson,
  noStyle,
  isNotFirstInOrder
}: {
  paths: IPartyDetailPaths;
  id?: string;
  initiator?: SigningInitiator
  salespersons?: AgencySalesperson[];
  signingOptions?: EntitySettingsSigningOptions
  removedData?: any,
  disabled?: {name?: boolean, email?: boolean, phone?: boolean},
  noUndefinedSalesperson?: boolean,
  partiallySignedConfiguration?: boolean,
  noStyle?: boolean,
  isNotFirstInOrder?: boolean,
  isLastInOrder?: boolean
}) {

  const online = useOnline();
  const [ _accordionActiveKey, setAccordionActiveKey ] = useState<string | string[] | null | undefined>([]);
  const accordionActiveKey = uniq([AccordionKey.IdentityVerification, AccordionKey.SignAsAuctioneer, AccordionKey.SignAsSalesperson, ...(Array.isArray(_accordionActiveKey) ? _accordionActiveKey : _accordionActiveKey ? [_accordionActiveKey] : [])]);
  const currentEntity = useCurrentEntity();

  const { value: signingParty, fullPath: signingPartyPath } = useLightweightTransaction<SigningParty>({
    parentPath: paths.meta.base,
    myPath: '',
    bindToMetaKey: true
  });
  const generalInstanceSigningPath = generateParentPath(paths.meta.base, 2);
  const { value: instanceAutoForm1 } = useLightweightTransaction<FormInstanceSigning['instanceAutoForm1']>({ parentPath: generalInstanceSigningPath, myPath: 'instanceAutoForm1', bindToMetaKey: true });

  const suggestSalesMode = [SignerProxyType.Auctioneer, SignerProxyType.Salesperson].includes(signingParty?.proxyAuthority);
  const proxyMode = Predicate.proxyNotSelf(signingParty?.proxyAuthority);
  const signingPartyOrderPath = `${signingPartyPath}.signingOrderSettings.auto`;
  const sublineageId = signingParty?.source.sublineageId;
  const editMeta = !sublineageId && (removedData || partiallySignedConfiguration);

  const rh = signingParty?.source.representationHierarchy;

  const nMyPath = editMeta ? `${paths.meta.base}.snapshot` : paths.data.base;

  const namePath = editMeta ? 'name' : paths.data.name;
  const phonePath = editMeta ? 'phone' : paths.data.phone;
  const emailLeafPath = editMeta ? 'email' : paths.data.email;
  const emailFullPath = Predicate.proxyNotSelf(signingParty.proxyAuthority) ? mergePaths(paths.meta.base, 'proxyEmail') : mergePaths(nMyPath, emailLeafPath);
  const { value: fieldEmail } = useLightweightTransaction<string|undefined>({ myPath: normalisePathToStr(emailFullPath), bindToMetaKey: editMeta || Predicate.proxyNotSelf(signingParty?.proxyAuthority) });

  const previousTypeHost = usePrevious(signingParty?.typeHostParty);

  const { value: partyDataDataModel } = useLightweightTransaction<AuthorityParty|AgencySalesperson|SigningPartySnapshot>({ myPath: nMyPath, bindToMetaKey: !!editMeta });
  const partyData = partyDataDataModel ?? editMeta;
  const { value: topLevelParty } = useLightweightTransaction<AuthorityParty>({ myPath: `${rh?.[0].accessKey}.[${rh?.[0].itemId??rh?.[0].position}]` });
  const { focusErrList } = useContext(WizardDisplayContext);

  const wetSignErrorMessage = signingParty?.type === SigningPartyType.SignWet && focusErrList.some(e => e === 'error-path-focus-mixedSigning')
    ? 'Counterpart signing is not permitted for this document. All parties must be configured to sign either digitally (on screen, or via link), or on paper.'
    : undefined;

  const {
    value: verificationConfig,
    handleUpdate: updateVerificationConfig,
    handleRemove: clearVerificationConfigRaw,
    fullPath: verificationPath
  } = useTransactionField<Maybe<SigningPartyVerificationConfig>>({
    parentPath: paths.meta.base,
    myPath: 'verification',
    bindToMetaKey: true
  });
  const {
    value: messageConfig,
    handleUpdate: updateMessageConfig,
    handleRemove: clearMessageConfig,
    fullPath: messageConfigPath
  } = useTransactionField<Maybe<SigningPartyMessageConfig>>({
    parentPath: paths.meta.base,
    myPath: 'message',
    bindToMetaKey: true
  });

  const { updateDraft: updatePartyDraft } = useYdocBinder<Maybe<SigningParty>>({ path: paths.meta.base, bindToMetaKey: true });

  function clearVerificationConfig (user?: boolean) {
    clearVerificationConfigRaw();
    if (user) {
      updatePartyDraft?.(party => {
        if (!party) return;
        party.verificationDefaultCleared = true;
      });
    }
  }

  function updateProxyAuthority(nv?: SignerProxyType | null) {
    updatePartyDraft?.(party =>{
      if (!party) return;
      if (nv == null) {
        delete party.proxyAuthority;
        return;
      }
      party.proxyAuthority = nv;
    });
  }

  const clearProxyAuthority = () => updateProxyAuthority();

  const memberEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);
  const allEntityMembers = (Object.values(memberEntities||{})
    .flatMap(e=>e?.salespeople||[]));

  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const propertyData = useLightweightTransaction<MaterialisedPropertyData>({ parentPath: '' })?.value;

  let partyDataForOptions: {linkedSalespersonId?: number | string, name?: string, isNotSalesperson?: boolean} | undefined = partyData && 'linkedSalespersonId' in partyData
    ? { linkedSalespersonId: partyData.linkedSalespersonId, name: partyData.name }
    : partyData && proxyMode && signingParty.proxyLinkedId
      ? { linkedSalespersonId: signingParty.proxyLinkedId, name: signingParty.proxyName, isNotSalesperson: signingParty.proxyAuthority !== SignerProxyType.Salesperson }
      : undefined;

  const isOverridingSigningPartySourceType = signingParty?.source.overrideType && signingParty?.source.overrideType !== signingParty?.source.type;
  if (isOverridingSigningPartySourceType) {
    partyDataForOptions = { name: partyData.name };
  }

  const { formName: formCode  } = useForm();
  const filteredOptions = buildFilteredSigningPartyTypeOptions({
    sessionInfo,
    signingParty,
    initiator,
    salespersons,
    partyData: partyDataForOptions,
    noUndefinedSalesperson,
    signingOptions,
    extraSettings: currentEntity?.signingOptions?.formSpecific?.[formCode as FormCode]
  });

  function clearLinkedProxyId () {
    updatePartyDraft?.(partyDraft => {
      if (!partyDraft) return;
      delete partyDraft.proxyLinkedId;
    });
  }

  // either the party is a remote signing party (always allowed) or the party is hosted (only if online)
  function resetSalespersonSigningMethod(overrideSigningParty?: SigningParty) {
    // Override is used because we may have have only just updated the party and so the the rendered
    // version from the hook is not up to date

    const party = overrideSigningParty??signingParty;
    const myAgentId = sessionInfo && 'agentId' in sessionInfo ? sessionInfo.agentId : undefined;
    if ([SigningPartyType.SignOnline, SigningPartyType.SignOnlineSms].includes(party?.type)) {
      if (party?.proxyLinkedId !== myAgentId) return;

      const proposedTypeComposite = `${SigningPartyType.SignInPerson}_${party.proxyLinkedId}`;
      const displayString = SigningPartyTypeOptions[SigningPartyType.SignInPerson].replace(/salesperson's/i, party?.proxyLinkedId !== myAgentId && party.proxyName ? `${party.proxyName}'s` : 'my');

      updatePartyDraft?.(partyDraft => {
        if (!partyDraft) return;
        partyDraft.typeHostComposite = proposedTypeComposite;
        partyDraft.typeHostComposite_display = displayString;
        applySplitPartySignerType(partyDraft);
      });
      return;
    }
    if ([SigningPartyType.SignInPerson].includes(party?.type)) {
      if (!(party?.proxyAuthority === SignerProxyType.Salesperson && party.proxyLinkedId)) return;

      const proposedTypeComposite = `${SigningPartyType.SignInPerson}_${party.proxyLinkedId}`;
      const displayString = SigningPartyTypeOptions[SigningPartyType.SignInPerson].replace(/salesperson's/i, party?.proxyLinkedId !== myAgentId && party.proxyName ? `${party.proxyName}'s` : 'my');

      updatePartyDraft?.(partyDraft => {
        if (!partyDraft) return;
        partyDraft.typeHostComposite = proposedTypeComposite;
        partyDraft.typeHostComposite_display = displayString;
        applySplitPartySignerType(partyDraft);
      });
      return;
    }
  }
  const { items: dropdownItems, allow } = buildCustomiseDropdownItems({
    isOnline: online,
    verificationConfig,
    messageConfig,
    signingParty,
    updateVerificationConfig: config => {
      updateVerificationConfig(config, true);
      setAccordionActiveKey(['3']);
    },
    updateMessageConfig: config => {
      updateMessageConfig(config, true);
      setAccordionActiveKey(['4']);
    },
    setPartyProxy: newProxy => {
      updateProxyAuthority(newProxy);
      switch (newProxy) {
        case SignerProxyType.Auctioneer:
          setAccordionActiveKey(['1']);
          break;
        case SignerProxyType.Salesperson: {
          setAccordionActiveKey(['2']);
          break;
        }
        default: {
          // changing to proxy will require no ID to be set
          clearLinkedProxyId();
        }
      }
    },
    formCode,
    saleMethod: propertyData?.sale?.saleMethod
  });

  useEffect(() => {
    const signingPartySourceType = signingParty.source?.overrideType || signingParty.source?.type;
    if (!signingPartySourceType) {
      // unspecified types are exempt from this config,
      // they'll probably be specified later on if they're the result of an async thingo
      return;
    }
    if (signingPartySourceType === SigningPartySourceType.Salesperson) {
      // salespeople are exempt from this config
      return;
    }

    if (signingParty?.type === SigningPartyType.SignOnline && (
      signingOptions?.requireIdRemote
      || (signingOptions?.defaultIdRemote && !signingParty?.verificationDefaultCleared)
    )) {
      updateVerificationConfig({
        type: SigningPartyVerificationType.Sms
      }, true);
    }

    if (signingParty?.type === SigningPartyType.SignInPerson && (
      signingOptions?.requireIdHosted
      || (signingOptions?.defaultIdHosted && !signingParty?.verificationDefaultCleared)
    )) {
      updateVerificationConfig({
        type: SigningPartyVerificationType.Sms
      }, true);
    }
    if (signingParty.type === SigningPartyType.SignWet) {
      clearVerificationConfig();
    }
  }, [signingParty?.type, signingParty?.source?.type, signingParty?.source?.overrideType]);

  const overrides: {[fieldKey: string]: string} = {};
  if (jointTypes.includes(topLevelParty?.partyType)) {
    overrides.onBehalfOf = topLevelParty?.onBehalfOf;
  }
  if (rh?.[rh?.length-1]?.accessKey === 'legalRepresentatives') {
    const rhi = rh[rh.length-1];
    const foundLR = (partyData as AuthorityParty)?.legalRepresentatives?.find(lr=>lr.id === rhi.itemId);

    if (foundLR) {
      overrides.personName1 = foundLR.name;
    }
  }

  // Provisionally removed as per #11975
  // const signingPhrase = partyData && partyData?.authority ? populateSigningPhrase(partyData, { overrides }) : undefined;
  const signingPhrase = '';
  // Note the conditional renderer still exists, until it is fully agreed this should be removed

  const configureAuto = !!(isNotFirstInOrder && (signingParty?.type === SigningPartyType.SignOnline || signingParty?.type === SigningPartyType.SignOnlineSms));

  const { updateDraft: updateSignerParty, rootBinder } = useYdocBinder<SigningParty>({ bindToMetaKey: true, path: signingPartyPath });
  const previousAuthority = usePrevious(signingParty?.proxyAuthority);
  useEffect(()=>{
    if (signingParty?.proxyAuthority !== SignerProxyType.Salesperson) return;
    if (signingParty?.typeHostParty && previousTypeHost !== signingParty?.typeHostParty) {

      const spId = parseInt2(signingParty.typeHostParty);
      const match = allEntityMembers.find(m=>m.id === spId);
      if (match) {
        updateSignerParty?.(party => {
          party.proxyName = match.name;
          party.proxyEmail = match.email;
          party.proxyPhone = match.phone;
          party.proxyLinkedId = match.id;
        });
      }
      resetSalespersonSigningMethod(getValueByPath(signingPartyPath, rootBinder?.get()??{}, true));
      return;
    }
    if (!signingParty.proxyName
      || (previousAuthority !== signingParty.proxyAuthority && previousAuthority != null) // ie was already loaded
    ) {
      const targetId = propertyData?.agent?.[0]?.salesp?.filter(sp=>sp.linkedSalespersonId)?.[0]?.linkedSalespersonId ?? sessionInfo?.agentId;

      const match = allEntityMembers.find(m=>m.id === targetId);
      if (match?.name) {
        updateSignerParty?.(party => {
          party.proxyName = match.name;
          if (match.email) party.proxyEmail = match.email;
          if (match.phone) party.proxyPhone = match.phone;
          if (match.id) party.proxyLinkedId = match.id;
        });
      }
    }
    resetSalespersonSigningMethod(getValueByPath(signingPartyPath, rootBinder?.get()??{}, true));
  }, [signingParty?.proxyAuthority, signingParty?.typeHostParty, signingParty?.type]); // Maybe don't use type host composite, because these other values are probably only updated later, and this might make it such that not all the data is up to date

  const representativeOptions = allEntityMembers
    .filter(sp=>(
      (signingParty?.proxyAuthority === SignerProxyType.Salesperson && sp.isSalesperson)
  || (signingParty?.proxyAuthority === SignerProxyType.Auctioneer && sp.isAuctioneer)
    ))
    .map(person=>{
      const label = person.name;
      return {
        label,
        ...person
      };
    }).sort(({ label: a }, { label: b }) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });

  function handleAgentSelect(selected: EntitySettingsSalesperson[]) {
    const selection = selected[0];
    if (selection.name) {
      updateSignerParty?.(party => {
        party.proxyName = selection.name;
        if (selection.email) party.proxyEmail = selection.email;
        if (selection.phone) party.proxyPhone = selection.phone;
        if (selection.id) party.proxyLinkedId = selection.id;
      });
      resetSalespersonSigningMethod(getValueByPath(signingPartyPath, rootBinder?.get()??{}, true));
    }
  }

  const pathsForSmsVerification = useMemo(()=>{
    if (!proxyMode) return paths;
    return {
      ...paths,
      data: {
        ...paths.data,
        phone: 'proxyPhone',
        base: paths.meta.base
      },
      dataIsMeta: true
    };
  }, [paths, proxyMode]);

  const signingMethodSelectCol = <Col>
    <WrField.Select
      label="Signing Method"
      options={filteredOptions}
      name="typeHostComposite"
      optionValueFilter={(prevVal) => {
        if (typeof prevVal === 'number') {
          prevVal = prevVal.toString();
        }
        if (typeof prevVal === 'string') {
          const splits = prevVal.split('_');
          return `${splits?.[0]}_${splits?.[1]??''}`;
        }
        return prevVal == null ? prevVal : undefined;
      }}
      parentPath={signingPartyPath}
      myPath="typeHostComposite"
      bindToMetaKey={true}
      forceFieldGroupFnName='splitPartySignerType'
      canClear={false}
    />
    {!!wetSignErrorMessage && <span key="message" className="text-danger">{wetSignErrorMessage}</span>}
  </Col>;

  const signingPartySourceType = signingParty?.source.overrideType || signingParty?.source.type;
  const shouldConsiderMissingEmailForForm1 = instanceAutoForm1 && sourceTypeGroup.get(signingPartySourceType) === SigningPartySourceType.Purchaser && formCode === FormCode.RSC_ContractOfSale;

  const shouldShowEmailField = signingParty?.type === SigningPartyType.SignOnline || shouldConsiderMissingEmailForForm1;

  const repLabel = SignerProxyAuthorityOptions[signingParty?.proxyAuthority];
  const representativeName = suggestSalesMode
    ? <WrField.AutoComplete
      label={`${repLabel}'s Name`}
      options={representativeOptions}
      name='name'
      bindToMetaKey={true}
      parentPath={paths.meta.base}
      myPath={'proxyName'}
      ydocForceKey={sublineageId}
      onSuggestSelect={handleAgentSelect}
      onChange={clearLinkedProxyId}
    />
    : <WrField.Control
      label={`${repLabel}'s Name`}
      parentPath={paths.meta.base}
      myPath={'proxyName'}
      name={paths.data.name}
      bindToMetaKey={true}
      ydocForceKey={sublineageId}
    />;

  const commonProxyBody = <div className='gap-3'>
    <Row><Col lg={6}>{representativeName}</Col>{signingMethodSelectCol}</Row>
    {([SigningPartyType.SignOnline, SigningPartyType.SignOnlineSms].includes(signingParty?.type)) && <Row className='mt-lg-3'>
      <Col>
        {shouldShowEmailField && <WrField.Control
          id={id && `signer_${id}_email`}
          parentPath={paths.meta.base}
          myPath={'proxyEmail'}
          name={'proxyEmail'}
          label={`${repLabel}'s Email`}
          bindToMetaKey={true}
          ydocForceKey={sublineageId}
        />}
        {signingParty?.type === SigningPartyType.SignOnlineSms && <WrField.Control
          id={id && `signer_${id}_phone`}
          parentPath={paths.meta.base}
          myPath={'proxyPhone'}
          name={'proxyPhone'}
          label={`${repLabel}'s Phone`}
          bindToMetaKey={true}
          ydocForceKey={sublineageId}
        />}
      </Col>
    </Row>}
  </div>;

  return <div
    key={`party-config-${signingParty?.id}`}
    className='party-configuration-item flex-grow-1'
    style={noStyle ? undefined : generatePartyConfigurationCardStyle(signingParty?.colour)}>
    <div className={clsJn({
      'd-flex flex-column py-3 pe-3 gap-3': true,
      'ps-3': !noStyle
    })}>
      {removedData && <h5 className='mb-2'>Removed:</h5>}
      <Row className={''}>
        <Col lg={6}>
          <Row>
            <Col>
              <WrField.Control
                parentPath={nMyPath}
                myPath={namePath}
                name={paths.data.name}
                label={'Name'}
                disabled={!!partyData?.linkedSalespersonId || disabled?.name}
                bindToMetaKey={!!editMeta}
                ydocForceKey={sublineageId}
              />
            </Col>
          </Row>

          {!proxyMode && <Row className={'mt-lg-3'}>
            <Col>
              {shouldShowEmailField && <WrField.Control
                id={id && `signer_${id}_email`}
                parentPath={nMyPath}
                myPath={emailLeafPath}
                name={paths.data.email}
                label={'Email'}
                disabled={signingPartySourceType === SigningPartySourceType.Salesperson || disabled?.email}
                bindToMetaKey={!!editMeta}
                ydocForceKey={sublineageId}
              />}
              {signingParty?.type === SigningPartyType.SignOnlineSms && <WrField.Control
                id={id && `signer_${id}_phone`}
                parentPath={nMyPath}
                myPath={phonePath}
                name={paths.data.phone}
                label={'Phone'}
                disabled={signingPartySourceType === SigningPartySourceType.Salesperson || disabled?.phone}
                bindToMetaKey={!!editMeta}
                ydocForceKey={sublineageId}
              />}
            </Col>
          </Row>}
        </Col>
        <Col lg={6}>
          {!proxyMode && <Row>
            {signingMethodSelectCol}
          </Row>}
          {!!(configureAuto || dropdownItems.length) && <Row className={'mt-lg-3'}>
            <Col className={'d-flex flex-row justify-content-between align-items-center form-floating-height'}>
              {configureAuto ? <div>
                <WrField.BoolCheck
                  name={signingPartyOrderPath}
                  myPath={signingPartyOrderPath}
                  bindToMetaKey={true}
                  label={'Automatic after previous signs'}
                  inline={false}
                />
              </div> : <div></div>}
              {dropdownItems.length ? <DropdownButton size={'lg'} variant={'link'} title={'Advanced Options'}>
                {dropdownItems}
              </DropdownButton> : <div></div>}
            </Col>
          </Row>}
        </Col>
      </Row>
      {shouldConsiderMissingEmailForForm1 && !fieldEmail && <Col>The Form 1 cannot be automatically distributed to this party without an email address.</Col>}
      {signingPhrase && <Row className={'mt-lg-3'}><Col className='text-muted small'><FormatBI>{signingPhrase}</FormatBI></Col></Row>}
      {((verificationConfig && allow.verification) || (messageConfig && allow.message) || proxyMode) && <Accordion
        activeKey={accordionActiveKey}
        onSelect={key => {
          const keys = new Set((Array.isArray(key) ? key : [key]).filter(Predicate.isNotNull));
          setAccordionActiveKey([...keys.values()]);
        }}
        alwaysOpen>
        {signingParty?.proxyAuthority === SignerProxyType.Auctioneer &&
          <CustomiseItemWrapper
            eventKey={'1'}
            title={`Sign as Auctioneer on behalf of ${getValueByPath(namePath, partyData, true)}`}
            icon={'gavel'}
            canDelete={true}
            onDeleteClick={clearProxyAuthority}
          >
            {commonProxyBody}
          </CustomiseItemWrapper>}
        {signingParty?.proxyAuthority === SignerProxyType.Salesperson &&
          <CustomiseItemWrapper
            eventKey={'2'}
            title={`Sign as Salesperson on behalf of ${getValueByPath(namePath, partyData, true)}`}
            icon={'person'}
            canDelete={true}
            onDeleteClick={clearProxyAuthority}
          >
            {commonProxyBody}
          </CustomiseItemWrapper>}
        {verificationConfig && allow.verification &&
          <CustomiseItemWrapper eventKey={AccordionKey.IdentityVerification} noCollapse={true}
            title='Two-Factor Authentication' icon={'badge'}
            canDelete={!((signingOptions?.requireIdRemote && signingParty?.type === SigningPartyType.SignOnline) || (signingOptions?.requireIdHosted && signingParty?.type === SigningPartyType.SignInPerson))}
            onDeleteClick={() => clearVerificationConfig(true)}>
            <CustomiseVerification path={verificationPath} paths={pathsForSmsVerification}
              phoneDisabled={disabled?.phone} representativeLabel={repLabel}></CustomiseVerification>
          </CustomiseItemWrapper>}
        {messageConfig && allow.message &&
          <CustomiseItemWrapper eventKey={AccordionKey.Message} title={'Personal Message'} canDelete={true}
            description={'Shown in the email and personal signing page for this party'}
            icon={'chat_bubble'}
            onDeleteClick={() => clearMessageConfig()}>
            <CustomiseMessage path={messageConfigPath}></CustomiseMessage>
          </CustomiseItemWrapper>}
      </Accordion>}
    </div>
  </div>;
}

export const MemoPartyConfigurationCard = memo(PartyConfigurationCard);

const defaultBorderStyle = '1px solid lightgrey';
const defaultPartyConfigurationCardStyle: React.CSSProperties = {
  borderTop: defaultBorderStyle,
  borderRight: defaultBorderStyle,
  borderBottom: defaultBorderStyle,
  borderLeft: defaultBorderStyle
};

export function generatePartyConfigurationCardStyle(partyColour?: string): React.CSSProperties {
  return partyColour
    ? {
      ...defaultPartyConfigurationCardStyle,
      borderLeft: `4px solid ${partyColour}`
    }
    : defaultPartyConfigurationCardStyle;
}
