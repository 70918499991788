import { useEffect, useContext } from 'react';
import { Agent, BenefitItem, MaterialisedPropertyData, TransactionMetaData } from '@property-folders/contract';
import { CollectionEditor } from './CollectionEditor';
import { useImmerYjs } from '../../hooks/useImmerYjs';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import { NarrowAgentInput } from './NarrowAgentInput';
import usePrevious from '../../hooks/usePrevious.js';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { v4 } from 'uuid';
import { companyTradingAs } from '@property-folders/common/util/formatting';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { useEntities } from '../../hooks/useEntity';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { stringifySaleAddress } from '@property-folders/common/util/stringifySaleAddress';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { FormContext } from '../../context/FormContext';

export function PrimaryAgentInput ({ showHeading = true, setHeaderActions, authRepMode }: { showHeading?: boolean, setHeaderActions?: ()=>void, authRepMode?: boolean }) {
  const { ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formName } = useContext(FormContext);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const memberEntities = useEntities() ?? {};
  const pathBase = authRepMode
    ? 'authRep'
    : 'agent';
  const creating = formName === 'newTrans';

  const { bindState: bindMetaState } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const {
    data: propertyMetaData,
    update: updateMeta
  } = bindMetaState(meta => meta);
  const { updateDraft: updateProperty } = useYdocBinder<MaterialisedPropertyData>({ path: '' });

  const { value: entities, handleInsert: insertAgent, handleClear: clearAgents } = useTransactionField<Agent[]>({ myPath: pathBase });
  const { handleUpdate: updateDataEntityId } = useTransactionField<string | number | undefined>({ myPath: `${pathBase}.[0].linkedEntityId` });
  const { value: agentList } = useLightweightTransaction<Agent[]>({ myPath: pathBase });
  const entity = agentList?.[0];
  const entityId = entity?.linkedEntityId;
  const previousEntity = usePrevious(entity) as any;
  const previousEntityId = previousEntity?.linkedEntityId;

  useEffect(() => {
    const noEntityMode = !entityId;
    if (!(
      updateMeta && sessionInfo && entities && entity && (
        (previousEntity && previousEntityId !== entityId) // An actual ID change
        || noEntityMode // Nothing set
      )
    )) {
      return;
    }

    let selectedEntityId = (
      typeof entityId === 'string'
        ? parseInt(entityId)
        : entityId
    ) || propertyMetaData?.entity?.id;
    if (!selectedEntityId) {
      // Sort required to determine lowest ID, as id's are created in order, and parents will thus
      // always have lower IDs than their children entities
      const [ defaultEntity ] = Array.from(Object.keys(memberEntities).map(e => memberEntities[e]))
        .filter(e => e?.archived !== true && e?.useNewPropertyTransactions)
        .sort((a, b) => (a?.entityId ?? 0) - (b?.entityId ?? 0));

      const defaultEntityId = defaultEntity?.entityId;

      if (!defaultEntityId) {
        return;
      }

      updateDataEntityId(defaultEntityId);
      selectedEntityId = defaultEntityId;
    }
    const sessionEntity = memberEntities[selectedEntityId];

    if (!sessionEntity) {
      return;
    }

    updateMeta(state => {
      if (state?.entity?.id === sessionEntity.entityId) {
        return;
      }
      state.entity = {
        id: sessionEntity.entityId,
        name: sessionEntity.name
      };
      state.creator = {
        id: sessionInfo.agentId,
        name: sessionInfo.name,
        timestamp: Date.now()
      };
    });

    const benefitTemplate = sessionEntity.dobDefaultTemplateId
      ? sessionEntity.dobTemplates?.find(t => t.id === sessionEntity.dobDefaultTemplateId)
      : undefined;
    updateProperty?.(draft => {
      if (!draft.benefits) {
        draft.benefits = {};
      }
      const benefits = draft.benefits;

      if (benefitTemplate) {
        benefits.enable = true;
        benefits.template = {
          id: benefitTemplate.id,
          name: benefitTemplate.name
        };
        benefits.benefits = benefitTemplate.items.map<BenefitItem>(item => ({
          id: v4(),
          itemDesc: item.nature,
          itemSource: item.source,
          itemCost: item.amount,
          itemRecipient: item.recipient
        }));
      } else {
        benefits.enable = false;
        if (benefits.template) {
          delete benefits.template;
        }
        if (benefits.benefits) {
          delete benefits.benefits;
        }
      }
    });

    const insertCurrentAgent = (entityId: number) => {
      const currentAgency = (memberEntities || {})[entityId.toString()] ?? {} as EntitySettingsEntity;
      const currentPerson = currentAgency.salespeople?.find(person=> person.id === sessionInfo.agentId);
      const compositeName = companyTradingAs(currentAgency.name, currentAgency.tradeName);
      if (!currentPerson?.id) console.warn('lost salesperson id on insert');
      const agencyInjection: Agent = {
        id: v4(),
        linkedEntityId: entityId,
        company: compositeName,
        profileName: currentAgency.profileName || compositeName,
        rla: currentAgency.rla,
        abn: canonicalisers.abnacn(currentAgency.abn).canonical as string,
        address: currentAgency.addressDisplay || stringifySaleAddress({ streetAddr_parts: { StreetName: currentAgency.address1, Suburb: currentAgency.suburb, Postcode: currentAgency.postcode, State: currentAgency.state } }),
        phone: currentAgency.phone,
        email: currentAgency.email,
        salesp: currentPerson?.isSalesperson
          ? [{
            id: v4(),
            name: currentPerson?.name || '',
            phone: canonicalisers.phone(currentPerson?.phone || '').canonical as string,
            email: canonicalisers.email(currentPerson?.email || '').canonical as string,
            linkedSalespersonId: currentPerson?.id
          }]
          : [],
        form1: {
          ...currentAgency.form1,
          serviceFaxOrEmail: currentPerson?.form1?.serviceFaxOrEmail || currentAgency.form1?.serviceFaxOrEmail,
          serviceAddress: currentPerson?.form1?.serviceAddress || currentAgency.form1?.serviceAddress,
          serviceAddressIsRla: currentPerson?.form1?.serviceAddress ? currentPerson?.form1?.serviceAddressIsRla : currentAgency.form1?.serviceAddressIsRla
        }
      };
      insertAgent(agencyInjection);
    };

    // On entity ID change, we don't make any changes, because the react element we're wrapping
    // should handle the relevant changes for that case. As such we only insert data if there's
    // nothing already.
    if ((!entities || (Array.isArray(entities) && entities.length === 0)) && noEntityMode) {
      insertCurrentAgent(selectedEntityId);
    } else if (noEntityMode) {
      clearAgents();
      insertCurrentAgent(selectedEntityId);
    }

  }, [entities, entity, entityId, updateMeta, sessionInfo]);

  return <CollectionEditor
    title={showHeading ? 'Agency' : undefined}
    level={2}
    myPath={pathBase}
    autoAddFirst={true}
    allowAdd={false}
    setHeaderActions={setHeaderActions}
    childItemRenderer={NarrowAgentInput}
    itemNoun={authRepMode ? 'Agent\'s Authorised Representative' : 'Agent'}
    childProps={{ primaryAgentMode: true, setHeaderActions, restrictAgentChange: true, creating }}
  />;
}
