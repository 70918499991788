import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import * as Y from 'yjs';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { SideNavMenuSizingContext } from '../../../dragged-components/SideNavMenuSizingContext';
import { useBreakpointValue } from '../../../hooks/useBreakpointValue';
import {
  ChangeSetItem,
  EditorInfo,
  mapFieldName,
  SubscriptionFormCode
} from '@property-folders/common/subscription-forms';
import { FormTypes, PartyCategory, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { FormUtil } from '@property-folders/common/util/form';
import { Predicate } from '@property-folders/common/predicate';
import { materialisePropertyData, materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';
import { DataSetKey, editHtmlDefinitions } from '@property-folders/common/subscription-forms/edit-html-definitions';
import { applyPdfChanges } from '@property-folders/common/util/pdf';
import { ContentType, FormOrderType } from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useGesture } from '@use-gesture/react';
import { Button, Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { InjectedSignatureSpace } from './InjectedSignatureSpace';
import { PDFPreviewer } from '../../../dragged-components/PDFViewer/PDFPreviewer';
import { WrappedFrameElement } from './WrappedFrameElement';
import { WrappedPlainElement } from './WrappedPlainElement';
import { AnnexureModal } from './AnnexureModal';
import { EditingModalContext } from './EditingModalContext';
import { injectServeFields } from '@property-folders/common/subscription-forms/injectServeFields';
import { applyAnnexures } from '@property-folders/common/subscription-forms/applyAnnexures';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../errors/modals';
import { dataMappingDefinitions } from '@property-folders/common/subscription-forms/data-mapping-definitions';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { MigrateFormModal } from '../../../dragged-components/subscriptionForms/MigrateFormModal';

export const PREVIEW_PSEUDO_TARGET = '#preview';

const ignoreEventNames = new Set([
  'react-devtools-bridge',
  'react-devtools-content-script'
]);

type UnexpectedExitReason =
  | undefined
  | { type: 'auth' }
  | { type: 'nolock' }
  | { type: 'already_open' };

export function applyChangesetToFrame(changes: ChangeSetItem[], frameWindow: Window | undefined | null, formCode: string) {
  if (!frameWindow) return [];

  const changedNames: string[] = [];

  for (const change of changes) {
    const elements = frameWindow.document.getElementsByName(change.targetName);
    for (const element of elements) {
      if (!('name' in element)) {
        console.warn('could not find name in element', element);
        return;
      }
      if (!('value' in element)) {
        console.log('could not find correct place to set value', element, change.value);
        continue;
      }

      const defn = dataMappingDefinitions[formCode as SubscriptionFormCode]?.fields?.[element.name as string];

      //dont update fields that nave no editor after the initial value is set
      if (defn?.editor === 'none' && element.value) continue;

      if (defn?.disabled) element.setAttribute('readonly', 'readonly');

      if (element.value === change.value) continue;

      element.value = change.value;
      changedNames.push((element as { name: string }).name);

      if (element.tagName === 'TEXTAREA' && 'CheckAnnex' in frameWindow && typeof frameWindow.CheckAnnex === 'function') {
        frameWindow.CheckAnnex(element);
      }
    }
  }

  return changedNames;
}

export const EditSubscriptionForm = forwardRef(function (
  {
    formId,
    formCode,
    yDoc,
    onIframeLoaded
  }: {
    formId: string,
    formCode: string,
    yDoc: Y.Doc,
    onIframeLoaded: () => void
  },
  ref
) {
  const navigate = useNavigate();
  const { hash: locationHash, pathname, search } = useLocation();
  const navType = useNavigationType();
  const sideNavSize = useContext(SideNavMenuSizingContext);
  const impartModalOffset = useBreakpointValue({ base: false, sm: false, md: true }, false);
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true);
  const [available, setAvailable] = useState(false);
  const [currentEditorInfo, setCurrentEditorInfo] = useState<EditorInfo | undefined>(undefined);
  const [showAnnexures, setShowAnnexures] = useState(false);
  const [previewUrl, internalSetPreviewUrl] = useState<string | undefined>(undefined);
  const [formMigrationState, setFormMigrationState] = useState<'Complete' | 'Waiting' | 'Required'>('Waiting');
  const [pendingFormMigrationChanges, setPendingFormMigrationChanges] = useState<string[]>([]);
  const onMigrateForm = () => {
    const documentId = doc?.subscription?.documentId;
    if (!documentId) {
      return;
    }

    return AjaxPhp.migrateFormToLatestVersion({ documentId })
      .then(() => {
        setFormMigrationState('Complete');
        setPendingFormMigrationChanges([]);
      });
  };
  const setPreviewUrl = (newUrl: string | undefined) => {
    if (newUrl) {
      navigate(pathname + search + PREVIEW_PSEUDO_TARGET, { preventScrollReset: true });
    }
    internalSetPreviewUrl(newUrl);
  };
  const [unexpectedExitReason, setUnexpectedExitReason] = useState<UnexpectedExitReason>(undefined);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dal = new PropertyFormYjsDal(yDoc, PropertyRootKey.Data, PropertyRootKey.Meta);
  const doc = dal.getFormInstance(formCode, formId);
  const annexureMunge = FormUtil.getAnnexuresFromFormInstance(doc)
    .map(x => x.id)
    .join(',');
  const debug = doc?.formCode
    ? FormTypes[doc.formCode].debug
    : false;
  const modalOffsetStyle = useMemo<React.CSSProperties>(() => {
    if (!impartModalOffset) return {};
    if (!sideNavSize.width) return {};

    return {
      marginLeft: `${sideNavSize.width / 2}px`
    };
  }, [impartModalOffset, sideNavSize.width]);

  useEffect(() => {
    if (!iframeRef.current?.contentWindow) {
      return;
    }

    const messageHandler = (e: any) => {
      if (!e.data) {
        return;
      }

      if (ignoreEventNames.has(e.data)) return;
      if (ignoreEventNames.has(e.data.source)) return;

      switch (e.data) {
        case 'unlock':
          setAvailable(true);
          break;
        case 'notauthorised':
          setUnexpectedExitReason({ type: 'auth' });
          break;
        case 'notlocked':
          setUnexpectedExitReason({ type: 'nolock' });
          break;
        case 'alreadyopen':
          setUnexpectedExitReason({ type: 'already_open' });
          break;
        case 'closed':
          console.log('navigate back for', e.data);
          navigate(-1);
          break;
        default: {
          if (typeof e.data !== 'string') {
            return;
          }

          try {
            const d: { action: string, path: string, filename?: string } = JSON.parse(e.data);
            switch (d.action) {
              case 'react_preview_pdf':
                setPreviewUrl(d.path);
                return;
              default:
                console.warn(`unexpected action: ${d.action} for path ${d.path}`);
                return;
            }
          } catch (e) {
            console.warn(e);
          }
        }
      }
    };

    //call save initially, so that preview works
    postMessageToFrame('save-silent');
    window.addEventListener('message', messageHandler);

    return () => window.removeEventListener('message', messageHandler);
  }, [iframeRef, formMigrationState]);

  const postMessageToFrame = useCallback((message: any) => {
    if (!iframeRef.current?.contentWindow) {
      console.warn('cannot postMessage to iframe', message);
      return;
    }

    iframeRef.current.contentWindow.postMessage(message, '*');
  }, [iframeRef]);

  // note because useImperativeHandle is such a fantastic name:
  // combined with this component being wrapped in `forwardRef` above,
  // this makes the preview() function available to the parent component to call.
  useImperativeHandle(ref, () => ({
    preview() {
      postMessageToFrame('preview');
    },
    annexures() {
      setShowAnnexures(true);
    }
  }));

  useEffect(() => {
    const bc = new BroadcastChannel(formId);

    bc.addEventListener('message', event => {
      if (event.data === 'opening') {
        // another tab is opening this form elsewhere.
        // we should navigate away without saving.
        postMessageToFrame('samebrowserclose');
      }
    });

    bc.postMessage('opening');

    return () => bc.close();
  }, []);

  const { enrichedElements, plainElements } = useMemo<{
    enrichedElements: { element: Element, editInfo: EditorInfo }[],
    plainElements: Element[]
  }>(() => {
    if (!doc?.subscription) return { enrichedElements: [], plainElements: [] };
    if (!available) return { enrichedElements: [], plainElements: [] };
    if (!iframeRef.current?.contentWindow) return { enrichedElements: [], plainElements: [] };

    const frameWindow = iframeRef.current.contentWindow;
    const subscription = doc.subscription;
    const noInfo: Element[] = [];
    const result = [...frameWindow.document.querySelectorAll('input:not([type="hidden"]), textarea, select')]
      .map(element => {
        const name = (element as { name?: string }).name;
        if (!name) return undefined;
        const editInfo = mapFieldName(name, subscription.fileName, doc?.order?.type === FormOrderType.Filler);
        if (!editInfo) {
          noInfo.push(element);
          return undefined;
        }
        return {
          element,
          editInfo
        };
      })
      .filter(Predicate.isNotNull);

    if (noInfo.length && debug) {
      console.log(`no editor information for the following fields in file "${doc.subscription.fileName}"`, noInfo.map(e => ({
        name: (e as { name?: string }).name,
        e
      })));
    }

    return { enrichedElements: result, plainElements: noInfo };
  }, [iframeRef, available, doc, formMigrationState]);

  useEffect(() => {
    if (!iframeRef.current?.contentWindow) return;

    const materialised = materialisePropertyData(yDoc);
    const meta = materialisePropertyMetadata(yDoc);
    for (const element of enrichedElements) {
      applyChangesetToFrame(element.editInfo.getChanges(materialised, meta), iframeRef.current.contentWindow, formCode);
    }

    postMessageToFrame('save-silent');
  }, [enrichedElements.length, iframeRef]);

  useEffect(() => {
    const documentId = doc?.subscription?.documentId;
    if (!documentId) {
      return;
    }

    AjaxPhp.checkFormLock({ documentId }).then(r => {
      const changes = r.changes ?? [];
      if (r.migrate && (changes).length > 0) {
        setFormMigrationState('Required');
        setPendingFormMigrationChanges(changes.map(c => c.description));
      } else {
        setFormMigrationState('Complete');
      }
    });
  }, []);

  const signatureDomNodes = useMemo(() => {
    if (!iframeRef.current?.contentWindow) return [];
    if (!doc?.subscription?.fileName) return [];
    if (!available) return [];

    const editHtmlDefinition = editHtmlDefinitions[doc.subscription.fileName];
    if (!editHtmlDefinition) return [];

    const documentNode = iframeRef.current.contentWindow.document;
    editHtmlDefinition.editFns.map(fn => fn(documentNode, debug));

    return [...documentNode.querySelectorAll(`[${DataSetKey.subFormsSignatureSpaceAttribute}]`)];
  }, [!!doc?.subscription?.fileName, !!iframeRef.current, available]);

  const openEditorFn = useCallback((editInfo: EditorInfo) => {
    if (!doc?.subscription) return;
    if (!available) return;
    if (!iframeRef.current?.contentWindow) return;

    setCurrentEditorInfo(editInfo);
  }, [iframeRef, available, doc]);

  const savePartial = useCallback((targetNames: string[]) => {
    if (!targetNames.length) return;

    postMessageToFrame(JSON.stringify({
      type: 'save',
      followUp: 'silent',
      partialNames: targetNames
    }));
  }, [postMessageToFrame]);

  const previewProcessPdfFunc = useCallback(async (blob: Blob) => {
    const data = await applyPdfChanges(await blob.arrayBuffer(), [
      async pdf => {
        if (!debug) return;
        // We need this for highlightTemplateForm1Fill
        const injections = FormTypes[formCode].subscription?.signing?.serveFields;
        await injectServeFields(pdf, injections);
      },
      async pdf => {
        await applyAnnexures(pdf, FormUtil.getAnnexuresFromFormInstance(doc));
      }
    ]);

    return new Blob([data], { type: ContentType.Pdf });
  }, [annexureMunge]);

  const navigateBack = useCallback(() => navigate(-1), []);

  if (!doc?.subscription) {
    return <></>;
  }

  const qs = LinkBuilder.buildQueryString({
    wrapped: true,
    DocumentID: doc.subscription.documentId,
    XCSS: 1,
    CoreModalsOnly: 1,
    AutoSaveOnExit: 1
  });
  const iframeUrl = LinkBuilder.reaformsFromRoot(`/iframe_editor.php?${qs.toString()}`);

  useGesture({
    onDrag: ({ swipe: swipeXY }) => {
      const swipeX = swipeXY[0];
      if (previewUrl && swipeX > 0) {
        navigate(-1);
      }
    }
  },
  {
    target: window,
    drag: { swipe: { velocity: 0.25, duration: 500 } }
  });

  useEffect(() => {
    if (previewUrl && locationHash !== PREVIEW_PSEUDO_TARGET && navType === 'POP') {
      setPreviewUrl(undefined);
      return;
    }
  }, [locationHash]);
  useEffect(() => {
    if (locationHash === PREVIEW_PSEUDO_TARGET && previewUrl == undefined) {
      navigate(pathname + search, { replace: true, preventScrollReset: true }); // This will retrigger this effect, but
      // having removed the hashes, nothing
      // should happen
    }
  }, [previewUrl]);

  if (formMigrationState === 'Waiting') {
    return <></>;
  }

  if (formMigrationState === 'Required') {
    return <MigrateFormModal migrateForm={onMigrateForm} changes={pendingFormMigrationChanges} />;
  }

  const handleEditTextField = (name: string) => {
    postMessageToFrame(JSON.stringify({
      type: 'field-edited',
      name: name
    }));
  };

  return <>
    {<Modal show={!!unexpectedExitReason} style={modalOffsetStyle} onHide={navigateBack}>
      <Modal.Body>
        {unexpectedExitReason?.type === 'auth' && 'You are not authorised to edit this document.'}
        {(unexpectedExitReason?.type === 'nolock' || unexpectedExitReason?.type === 'already_open') && 'Could not obtain/maintain a lock on this document.'}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={navigateBack}>Ok</Button>
      </Modal.Footer>
    </Modal>}
    <div className="w-100 d-flex flex-column">
      <div style={{ height: 'calc(100vh - 96px)' }}>
        <iframe
          style={{ border: 'none', width: '100%' }}
          ref={iframeRef}
          src={iframeUrl}
          onLoad={() => onIframeLoaded?.()}
        />
        {enrichedElements.map(({ element, editInfo }, index) => {
          return editInfo.editor === 'none'
            ? <WrappedPlainElement key={`plain${index}`} element={element} onChange={savePartial} onEdit={handleEditTextField} />
            : <WrappedFrameElement key={index} element={element} editInfo={editInfo} onOpen={openEditorFn} iframe={iframeRef.current} onChange={savePartial} />;
        })}
        {plainElements.map((element, index) => {
          return <WrappedPlainElement key={`plain${index}`} element={element} onChange={savePartial} onEdit={handleEditTextField} />;
        })}
        {signatureDomNodes.map((node, index) => {
          const partyType = (node as HTMLElement).dataset[DataSetKey.subFormsSignatureSpace] as PartyCategory;
          const party = (FormTypes[formCode].parties || []).find(p => p.type === partyType);
          return createPortal(<InjectedSignatureSpace party={party} />, node, index.toString());
        })}
      </div>
    </div>
    {showAnnexures && <ErrorBoundary fallbackRender={fallback => <FallbackModal {...fallback} show={!!showAnnexures}
      onClose={() => setShowAnnexures(false)} />}>
      <AnnexureModal
        onComplete={() => {
          setShowAnnexures(false);
        }}
        formId={formId}
        formCode={formCode}
        yDoc={yDoc}
        offsetStyle={modalOffsetStyle}
        transactionMetaRootKey={PropertyRootKey.Meta}
      />
    </ErrorBoundary>}
    {!!currentEditorInfo && <ErrorBoundary
      fallbackRender={fallback => <FallbackModal {...fallback} show={!!currentEditorInfo}
        onClose={() => setCurrentEditorInfo(undefined)} />}>
      <EditingModalContext
        editInfo={currentEditorInfo}
        onComplete={(changedNames) => {
          savePartial(changedNames);
          setCurrentEditorInfo(undefined);
        }}
        frameWindow={iframeRef.current?.contentWindow}
        ydoc={yDoc}
        offsetStyle={modalOffsetStyle}
        formCode={formCode as SubscriptionFormCode}
      />
    </ErrorBoundary>}
    {!!previewUrl && <PDFPreviewer
      url={previewUrl}
      onClose={() => navigate(-1)}
      tryMakeScrollWork={true}
      processPdf={previewProcessPdfFunc}
      renderTextLayer={renderTextLayer}
    />}
  </>;
});
