import { useContext, useEffect } from 'react';
import { useLightweightTransaction, useTransactionField } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { boolYesNoOpts } from '@property-folders/common/data-and-text/constants';
import { AddressSelector } from '../AddressSelector';
import { CollectionRemoveButton } from '../CollectionRemoveButton';
import { WrField } from '../CommonComponentWrappers';
import './ContractSpecialConditions.scss';
import { useYdocBinder } from '../../../hooks/useYdocBinder';
import { usePropertyGnafLocation } from '../../../hooks/usePropertyGnafLocation';
import { CollectionEditor } from '../CollectionEditor';
import { PartyType } from '@property-folders/contract';
import type { ContractSpecialType, PurchaserParty, SaleAddress, SaleTitle, TitleDivision } from '@property-folders/contract';
import { proposedPrefixTextGen } from '@property-folders/common/yjs-schema/property';
import { LineageContext } from '../../../hooks/useVariation';

const FinanceProperties = ({ myPath, parentPath, hideDelete = false, removable = true }: TransactionConsumerProps & {removable?: boolean, hideDelete?: boolean}) => {
  const { fullPath, handleRemove } = useTransactionField({ myPath, parentPath });

  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={handleRemove} />;
  const gnafId = usePropertyGnafLocation();

  return <div className="w-100 mt-2 d-flex">
    <div className='flex-grow-1'>
      <AddressSelector label='Address of property to be financed' parentPath={fullPath} myPath='addrSingleLine' gnafCentre={gnafId} />
    </div>

    {(!hideDelete) && <div className='d-flex align-items-center delete-div ms-1'>
      {closeButton}
    </div>}
  </div>;
};

export const ContractSpecialConditionsFinance = ({ purchaserMode, radioEnable }: { purchaserMode?: boolean; radioEnable?: boolean }): JSX.Element => {
  const parentPath = 'contractSpecial';
  const { value } = useLightweightTransaction<ContractSpecialType>({ myPath: parentPath });
  const { updateDraft } = useYdocBinder<ContractSpecialType>({ path: parentPath });
  const { value: titles } = useLightweightTransaction<SaleTitle[]|undefined>({ myPath: 'saleTitles' });
  const { value: addresses } = useLightweightTransaction<SaleAddress[]|undefined>({ myPath: 'saleAddrs' });
  const { value: primaryPurchaser } = useLightweightTransaction({ myPath: 'primaryPurchaser' }) as { value: string | undefined };
  const { value: purchasers } = useLightweightTransaction<PurchaserParty[]>({ myPath: 'purchasers' });
  const { value: titleDivision } = useLightweightTransaction<TitleDivision|undefined>({ myPath: 'titleDivision' });
  const isPrimaryPurchaserAnIndividual = !!(purchasers?.find((purchaser) => purchaser.id === primaryPurchaser)?.partyType === PartyType.Individual);

  const { variationsMode, snapshotData } = useContext(LineageContext);
  const doNotRenderHide = purchaserMode || (variationsMode && !snapshotData?.contractSpecial?.hideFinance);

  const properties: {id: string, label: string, type: 'title' | 'address'}[] = [];

  const unboundTitles = Array.isArray(titles) && titles.length > 0 && titles.filter(t => !(t.linkedAddresses && Array.isArray(t.linkedAddresses) && t.linkedAddresses.length > 0));
  let proposedPrefix = proposedPrefixTextGen(titleDivision?.depositChoice, titleDivision?.proposedLots, titles);

  if (proposedPrefix) proposedPrefix = proposedPrefix + ', ';
  else proposedPrefix = ''; // Just in case we aren't getting back an empty string

  for (const address of addresses||[]) {
    properties.push({ id: address.id, type: 'address', label: `${proposedPrefix}${address.streetAddr}, ${address.subStateAndPost}` });
  }
  for (const title of unboundTitles||[]) {
    properties.push({ id: title.id, type: 'title', label: `${proposedPrefix}${title.isWhole?'Whole':'Portion'} of ${title.title}` });
  }

  const nonEmptyAlternativeSecurities = value?.financeProperties?.filter(p=>p.addrSingleLine);
  const noOtherProperties = !nonEmptyAlternativeSecurities?.length;

  useEffect(()=>{
    if (!noOtherProperties || value?.propertyNotPartOfSecurity === false) return;
    updateDraft?.(draft => {
      if (!draft) return;
      draft.propertyNotPartOfSecurity = false;
    });
  }, [nonEmptyAlternativeSecurities?.length]);

  const sectionApplicable = value?.financeRequired;
  const propertyCheckbox = <WrField.BoolCheck disabled={!nonEmptyAlternativeSecurities?.length} parentPath={parentPath} myPath='propertyNotPartOfSecurity' name={parentPath+'.propertyNotPartOfSecurity'} inverter={true}/>;
  return <div className="w-100">
    <div className="subsection">
      {!doNotRenderHide && <div className="w-100 mt-2">
        <WrField.BoolCheck name='hideFinanceSC' label={'Do not print this Special Condition'} parentPath={parentPath} myPath='hideFinance' />
      </div>}
      {!value?.hideFinance && <>
        <div className="w-100 mt-2">
          <WrField.CheckRadio
            label={purchaserMode && isPrimaryPurchaserAnIndividual ? 'Will your purchase be subject to you obtaining finance?' : 'Will the sale be subject to the Purchaser obtaining finance?'}
            radioType={radioEnable ? 'radio' : 'checkbox'}
            options={boolYesNoOpts}
            valueType='boolean'
            inline={true}
            name='financeRequired'
            myPath='contractSpecial.financeRequired'
          />
        </div>
        {sectionApplicable && <>
          {purchaserMode && <div className="w-100 mt-2">
            <p>We need to know some details about the finance that is being sought, in order to assess whether it is
            reasonable.</p>
            <p>If your offer is accepted, tell us how much time you will need to get approval from a financier. After this
            time, if the finance condition has not been satisfied or waived by you, then the contract may be terminated
            by the vendor.</p>
          </div>}
          <div className="w-100 d-flex">
            <div className='flex-grow-1 mt-2' style={{ width: '200px' }}>
              <WrField.Control
                type='date'
                dateFromToday={true}
                label='Latest date for approval'
                name='financeDeadline'
                myPath='contractSpecial.financeDeadline'
                useCanonical={true}
              />
            </div>
          </div>
          <div className="w-100 d-flex">
            <div className='flex-grow-1 mt-2' style={{ width: '200px' }}>
              <WrField.Control
                label='Maximum amount to be borrowed'
                name='financeAmount'
                myPath='contractSpecial.financeAmount'
              />
            </div>
            <div className='flex-grow-1 mt-2' style={{ width: '100px' }}>
              <WrField.Control
                label='Maximum interest rate acceptable'
                name='financeInterestRate'
                myPath='contractSpecial.financeInterestRate'
              />
            </div>
          </div>
          {!purchaserMode && <div className='mt-2 w-100'>
            <div className='fs-4 mt-3'>Property to be secured</div>

            {
              properties.length > 1
                ? <div className='d-flex mt-2'>
                  <div>{propertyCheckbox}</div>
                  <div>
                    <div><span className='fw-bold'>The Property</span></div>
                    {properties.map(s => <div className='mt-1 ms-3'>{s.label}</div>)}
                  </div>
                </div>
                : properties.map(s => <div className='mt-1 d-flex'>
                  <div className=''>{propertyCheckbox}</div>
                  <div className='fw-bold'>{s.label}</div>
                </div>)
            }
            <CollectionEditor
              level={4}
              autoAddFirst={false}
              allowAdd={true}
              myPath={'contractSpecial.financeProperties'}
              childItemRenderer={FinanceProperties}
              itemNoun='financed property'
              addTooltip='Add properties which are being financed'
              overrideAdd={() => ({ enable: true })}
            />
          </div>}
        </>}
      </>}
    </div>

  </div>;

};
