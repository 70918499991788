import { boolYesNoOpts } from '@property-folders/common/data-and-text/constants';
import { WrField } from '../CommonComponentWrappers';
import './ContractSpecialConditions.scss';
import { useContext } from 'react';
import { LineageContext } from '../../../hooks/useVariation';

export const TemplateContractSpecialConditionsFinance = ({ radioEnable }: {radioEnable?: boolean}): JSX.Element => {

  const { variationsMode, snapshotData } = useContext(LineageContext);
  const doNotRenderHide = variationsMode && !snapshotData?.contractSpecial?.hideFinance;
  return <div className="w-100">
    <div className="subsection">
      {!doNotRenderHide  && <div className="w-100 mt-2">
        <WrField.BoolCheck name='hideFinanceSC' label={'Do not print this Special Condition'} parentPath={'contractSpecial'} myPath='hideFinance' />
      </div>}
      <div className="w-100 mt-2">
        <WrField.CheckRadio
          label='Will the Vendor consider offers where the Sale is subject to the Purchaser obtaining finance?'
          radioType={radioEnable? 'radio' : 'checkbox'}
          options={boolYesNoOpts}
          valueType='boolean'
          inline={true}
          name='financePermitted'
          myPath='contractSpecial.financePermitted'
        />
      </div>

    </div>
  </div>;
};
