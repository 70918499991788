import {
  WizardSidebarSubsetProps,
  WizardStepPage
} from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import {
  ContentType,
  FormCodeUnion,
  FormInstance,
  FormInstanceSigning,
  FormOrderState,
  FormOrderType,
  FormSigningState,
  ServeStateRecipient,
  SignedStates,
  SigningParty
} from '@property-folders/contract';
import {
  fieldIsSigned,
  getCompletedFiles,
  isRecipientServed,
  mapSigningPartySourceTypeToCategory,
  PropertyFormYjsDal,
  sourceUniqueKey
} from '@property-folders/common/yjs-schema/property/form';
import { Button } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { formatTimestamp, getTimeString } from '@property-folders/common/util/formatting';
import { getEmailEventText } from '@property-folders/components/dragged-components/signing/PartySessionCard';
import { ServeToNewPurchaserModal } from '@property-folders/components/dragged-components/signing/ServeToNewPurchaserModal';
import { MarkServedModal } from '@property-folders/components/dragged-components/signing/MarkServedModal';
import { generateFormFileName, materialisePropertyData } from '@property-folders/common/yjs-schema/property';
import { fillPdf } from '@property-folders/common/signing/fill-pdf';
import { AppFileProvider } from '@property-folders/components/dragged-components/signing/SigningProcess';
import * as Y from 'yjs';
import { PDFPreviewer } from '@property-folders/components/dragged-components/PDFViewer/PDFPreviewer';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { SplitIfManyButton } from '@property-folders/components/dragged-components/SplitIfManyButton';
import { UpdateServeViaLinkPurchaserModal } from '@property-folders/components/dragged-components/signing/UpdateServeViaLinkPurchaserModal';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { FormUtil } from '@property-folders/common/util/form';
import { useOnline } from '../../hooks/useOnline';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { Form1RequiredSection } from './Form1RequiredSection';
import { hashPurchasers, produceContactDetailsSnapshotCompareString, remapSignerToContactPurchaser } from '@property-folders/common/util/purchaserHash';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { SubscriptionFormTypes } from '@property-folders/common/subscription-forms';
import { WrField } from '../form/CommonComponentWrappers';
import { getPathParentAndIndex, getValueByPath, mergePaths, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { useEntity } from '../../hooks/useEntity';
import { bind } from 'immer-yjs';

export function ServeToPurchaserSection({
  name,
  label,
  icon,
  iconPack,
  embedded,
  formId,
  formCode,
  signerSourceFormCode,
  propertyId,
  metaPath,
  contractMode,
  contractInstancePath,
  contractYdocMetaKey,
  contractSignedDate,
  latestPurchasers,
  originatingForm
}: WizardSidebarSubsetProps & {
  signerSourceFormCode: FormCodeUnion,
  formCode: FormCodeUnion,
  formId?: string,
  propertyId?: string,
  metaPath?: string,
  contractMode?: boolean,
  contractInstancePath?: PathType,
  contractYdocMetaKey?: string,
  contractSignedDate?: string,
  // if this section is being rendered from within a document with purchasers (e.g. contract of sale)
  latestPurchasers?: SigningParty[];
  originatingForm?: ServeStateRecipient['originatingForm']
}) {
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  if (!ydoc || !formCode || !formId) return;

  metaPath = metaPath || FormUtil.getFormPath(formCode, formId);

  const { value: instance } = useLightweightTransaction<FormInstance>({
    parentPath: metaPath,
    myPath: '',
    bindToMetaKey: true
  });
  const dal = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey);
  const { recipients: familyRecipients, unservedRecipients } = dal.getFormFamilyServeCombinedRecipients(formCode);
  const { value: contractSigning } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: contractInstancePath??'invalid',
    myPath: 'signing',
    ydocForceKey: contractYdocMetaKey??'invalid'
  });

  const notSigningContract = contractMode && contractSigning?.state != null && !SignedStates.has(contractSigning?.state);

  const form1Signing = instance?.signing;
  const servingAllowed: boolean = useMemo(() => {
    return SignedStates.has(instance?.signing?.state) && !(instance?.order?.type === FormOrderType.Filler && instance?.order?.state === FormOrderState.ReturnedToClient);
  }, [form1Signing, instance]);
  const [showAddPurchaser, setShowAddPurchaser] = useState(false);
  const [showEditLinkPurchaserId, setShowEditLinkPurchaserId] = useState('');
  const [showMarkServedRecipientId, setShowMarkServedRecipientId] = useState('');
  const [showServedCopyUrl, setShowServedCopyUrl] = useState('');
  const [selectedPurchaserId, setSelectedPurchaserId] = useState<string|undefined>('');
  const [showForm1RequiredModal, setShowForm1RequiredModal] = useState(false);
  const isOffline = !useOnline();
  const navigate = useNavigate();

  const { recipients, oldRecipientsMappingBySource, oldRecipientsMappingByRecipientId } = useMemo(() => {
    if (!ydoc) return { recipients: [] }; // latest purchasers do not exist in the form 1, so we can't rely on their presence
    const mostRecentRecipientPerPerson = new Map<string, ServeStateRecipient>();
    const purchaserEmails = latestPurchasers?.map(p => p.snapshot?.email)?.filter(Boolean);
    const purchaserSources = latestPurchasers?.map(p => sourceUniqueKey(p.source))?.filter(Boolean);
    const oldRecipientsMappingBySource = dal.mapForm1RecipientsToSourceId(familyRecipients);
    const oldRecipientsMappingByRecipientId = new Map([...oldRecipientsMappingBySource.entries()].map(([_k, v]) => [v.recipient.id, v]));

    for (const recipient of familyRecipients || []) {
      const { source } = oldRecipientsMappingByRecipientId.get(recipient.id) ?? {};
      const emailIsIncluded = recipient.email && purchaserEmails?.includes(recipient.email??'');
      const idIsIncluded = source && purchaserSources?.includes(sourceUniqueKey(source));

      if (contractMode && !emailIsIncluded && !idIsIncluded) continue;
      const key = `n:${recipient.name};e:${recipient.email}`;
      const existing = mostRecentRecipientPerPerson.get(key);
      if (!existing) {
        mostRecentRecipientPerPerson.set(key, recipient);
      } else {
        if ((recipient.timestamp || 0) > (existing.timestamp || 0)) {
          mostRecentRecipientPerPerson.set(key, recipient);
        }
      }
    }
    const resRecip = [...mostRecentRecipientPerPerson.values()]
      //use recipient for specific instance if this is archived
      ?.map(r => instance?.archived ? r.servedInstances?.[instance.id] : r)
      ?.filter(Boolean);

    return { recipients: resRecip, oldRecipientsMappingBySource, oldRecipientsMappingByRecipientId };
  }, [familyRecipients, latestPurchasers, ydoc]);

  //handle purchaser details changes - update recipients
  useEffect(()=> {
    if (!ydoc || !formId || !latestPurchasers?.length || !oldRecipientsMappingBySource) return;
    const formDal = new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey);

    for (const purchaserSigner of latestPurchasers) {
      const source = purchaserSigner.source;
      const { recipient, signingParty: storedSigningParty } = oldRecipientsMappingBySource.get(sourceUniqueKey(source))??{};
      if (!recipient || !storedSigningParty) continue;
      const latestSnap = purchaserSigner.snapshot;
      if (!latestSnap || ((!latestSnap?.email || recipient.email === latestSnap?.email)
        && recipient.name === latestSnap?.name
        && (!latestSnap?.addressSingleLine || recipient.address === latestSnap?.addressSingleLine))) continue;
      formDal.updateServeRecipientEmail(formCode, formId, recipient.id, latestSnap?.email, latestSnap?.company || latestSnap?.name, latestSnap?.addressSingleLine);
    }
  }, [latestPurchasers, ydoc, formId, oldRecipientsMappingBySource]);

  useEffect(()=> {
    setShowForm1RequiredModal(!instance && !recipients?.length);
  }, [recipients, instance]);

  const unServedPurchasers = useMemo(() => {
    if (!latestPurchasers?.length) return [];
    let unusedRecipients = cloneDeep(recipients);
    const unserved = [];

    for (const purchaser of latestPurchasers) {
      const pSourceKey = sourceUniqueKey(purchaser.source);
      let found = unusedRecipients?.find(ur => oldRecipientsMappingByRecipientId?.get(ur.id)?.sourceKey === pSourceKey);
      if (!found) found = unusedRecipients?.find(ur => ur.email && ur.email === purchaser.snapshot?.email);
      if (!found) {
        unserved.push(purchaser);
      } else {
        unusedRecipients = unusedRecipients?.filter(ur => ur.id !== found.id);
      }
    }

    return unserved;
  }, [form1Signing, latestPurchasers]);

  const form1Name = latestPurchasers?.map(p=>p.snapshot?.name).join(', ');
  const form1Address = latestPurchasers?.find(p => p.snapshot?.isPrimary)?.snapshot?.addressSingleLine;
  const purchasersHash = useMemo(()=> {
    if (!latestPurchasers) return undefined;
    return hashPurchasers(latestPurchasers.map(p=>{
      const matchedRecip = oldRecipientsMappingBySource?.get(sourceUniqueKey(p.source));
      if (!matchedRecip) return p;
      return {
        ...p,
        id: matchedRecip.recipient.id // Needed to try and mask legacy hash storage to try and flag them as not modified
      };
    }));
  }, [latestPurchasers]);
  const purchasersCompareString = useMemo(()=> latestPurchasers
    ? produceContactDetailsSnapshotCompareString(remapSignerToContactPurchaser(latestPurchasers))
    : undefined, [latestPurchasers]);

  const handleDownload = async (recipient: ServeStateRecipient) => {
    if (!ydoc || !formId) return;
    if (!form1Signing?.session?.id) return;

    try {
      await buildAndDownloadServeForm({
        ydoc,
        name: form1Name || recipient.name,
        address: form1Address || recipient.address,
        contractDate: recipient.contractDate,
        formCode,
        formId,
        formDal: new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey),
        timeZone: form1Signing.session.initiator.timeZone,
        signingSessionId: form1Signing.session.id,
        forceRegen: true
      });
    } catch (err: unknown) {
      console.error(err);
    }
  };

  const handleDownloadCancel = async (recipient: ServeStateRecipient) => {
    if (!ydoc || !formId) return;
    new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey).resetDownloadServingForRecipient({
      formCode, formId, recipientId: recipient.id
    });
  };

  const handleViewManuallyServed = async (fileId: string) => {
    // future: maybe involve file sync provider for situations where the file may not exist locally yet.
    const file = await FileStorage.readFileOnly(fileId);
    if (!file) return '';
    setShowServedCopyUrl(URL.createObjectURL(file));
  };

  const handleViewServed = (recipientId: string) => {
    setShowServedCopyUrl(LinkBuilder.publishedDocument(recipientId));
  };

  const handleReserve = (recipientId: string) => {
    const { recipient, sourceKey: rSourceKey } = oldRecipientsMappingByRecipientId?.get(recipientId)??{};
    let purchaser = latestPurchasers?.find(p => sourceUniqueKey(p.source) === rSourceKey);
    if (!purchaser) purchaser = latestPurchasers?.find(p => p.snapshot?.email && p.snapshot?.email === recipient?.email);
    setSelectedPurchaserId(purchaser?.id);
    setShowAddPurchaser(true);
  };

  const forceServe = (recipientId: string) => {
    dal.forceServeRecipient(formCode, formId, recipientId);
  };

  const serveUnservedPurchaser = (id: string) => {
    setSelectedPurchaserId(id);
    setShowAddPurchaser(true);
  };

  const removeUnservedCustomRecipient = (id: string) => {
    dal.removeDownloadCancelledCustomRecipient({ formCode, formId, recipientId: id });
  };

  const navigateToForm1 = () => {
    if (!propertyId) return;
    if (!formId) return;
    navigate(LinkBuilder.documentPath(
      { id: propertyId },
      { id: formId },
      Object.keys(SubscriptionFormTypes).includes(formCode)));
  };

  const getForm1Status = (instance: FormInstance) => {
    if (!instance) return '';

    if (instance?.order) {
      if (instance?.order?.type === FormOrderType.Filler) {
        if (instance?.order?.state === FormOrderState.ReturnedToClient) return 'The Form 1 must be returned before serving is permitted';
        return '';
      }

      return <div className={'d-flex align-items-center'}>
        {`Form 1 ordered from Eckermann Property Forms on ${formatTimestamp(instance?.order?.job?.requestedAtMs, undefined, false)}.`}
        <Button variant={'outline-secondary'} className={'ms-2'} onClick={navigateToForm1}>View order</Button>
      </div>;
    } else {
      return <div className={'d-flex align-items-center'}>
        Form 1 is incomplete.
        {contractMode && <Button variant={'outline-secondary'} className={'ms-2'} onClick={navigateToForm1}>View draft</Button>}
      </div>;
    }
  };

  const localEntityAutoForm1 = useEntity(instance?.signing?.sessionInitiator?.entity.id)?.signingOptions?.autoServeForm1;

  useEffect(()=>{
    if (!(contractMode && contractInstancePath && contractYdocMetaKey && localEntityAutoForm1 != null)) return;
    const metaMap = ydoc?.getMap(contractYdocMetaKey);
    if (!metaMap) return;
    const metaBinder = bind(metaMap);
    const signing = (getValueByPath(contractInstancePath, metaMap.toJSON(), true) as FormInstance | undefined)?.signing;
    if (!signing) return;
    if (signing?.instanceAutoForm1 == null) {
      metaBinder.update?.(draft => {
        const { parent, indexer } = getPathParentAndIndex(contractInstancePath, draft, true);
        const fi = parent[indexer] as FormInstance;
        if (!fi.signing) {
          return;
        }
        fi.signing.instanceAutoForm1 = !!localEntityAutoForm1;
      });
    }
    metaBinder.unbind();
  }, [contractMode, contractInstancePath, contractYdocMetaKey, localEntityAutoForm1]);

  if (!servingAllowed || showForm1RequiredModal) {
    return <WizardStepPage
      name={name}
      label={contractMode ? 'Form 1 required for Cooling-Off' : label}
      icon={icon}
      iconPack={iconPack}
      embedded={embedded}
      headerContent={contractMode && notSigningContract && contractInstancePath && contractYdocMetaKey && <WrField.BoolCheck
        key='auto-form-1'
        title='Purchasers will be served the Form 1 automatically by email if an email is provided'
        label='Serve Form 1 automatically after signing'
        name={'signing.instanceAutoForm1'}
        nullishIsFalse={true}
        ydocForceKey={contractYdocMetaKey}
        parentPath={normalisePathToStr(mergePaths(contractInstancePath, 'signing'))}
        myPath='instanceAutoForm1'
      />}
    >
      {instance && getForm1Status(instance)}

      {showForm1RequiredModal && propertyId && latestPurchasers &&
        <Form1RequiredSection
          formCode={formCode}
          purchasers={latestPurchasers}
          propertyId={propertyId}
          contractSigned={!!contractSignedDate}
          key='mark_served'
        />
      }
    </WizardStepPage>;
  }

  const servePurchaserModalPurchaser = contractMode ? latestPurchasers?.find(p => p.id === selectedPurchaserId) : undefined;
  const unservedPurchaserModalRecipient = !servePurchaserModalPurchaser && unservedRecipients?.find(p => p.id === selectedPurchaserId);
  const servePurchaserModalRecipientId = servePurchaserModalPurchaser ? oldRecipientsMappingBySource?.get(sourceUniqueKey(servePurchaserModalPurchaser.source))?.recipient.id : undefined;

  const unserved = [...((!contractMode && unservedRecipients) || []), ...(contractMode ?unServedPurchasers:[])];

  function handleClosePurchaserModal() {
    setShowAddPurchaser(false);
    setSelectedPurchaserId('');
  }

  return <WizardStepPage
    name={name}
    label={label}
    icon={icon}
    iconPack={iconPack}
    embedded={embedded}
    headerContent={contractMode
      ? notSigningContract && contractInstancePath && contractYdocMetaKey && <WrField.BoolCheck
        key='auto-form-1'
        title='Purchasers will be served the Form 1 automatically by email if an email is provided'
        label='Serve Form 1 automatically after signing'
        name={'signing.instanceAutoForm1'}
        nullishIsFalse={true}
        ydocForceKey={contractYdocMetaKey}
        parentPath={normalisePathToStr(mergePaths(contractInstancePath, 'signing'))}
        myPath='instanceAutoForm1'
      />
      : !instance?.archived &&  <Button
        variant='outline-secondary'
        onClick={() => setShowAddPurchaser(true)}
      >
        New Purchaser
      </Button>
    }
  >
    {showAddPurchaser && formId && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={handleClosePurchaserModal} />}>
      <ServeToNewPurchaserModal
        formId={formId}
        formCode={formCode}
        onClose={handleClosePurchaserModal}
        key='add_purchaser'
        purchaser={servePurchaserModalPurchaser}
        unservedRecipient={unservedPurchaserModalRecipient||undefined}
        recipientId={servePurchaserModalRecipientId}
        form1Name={form1Name}
        form1Address={form1Address}
        contractMode={contractMode}
        contractSignedDate={contractSignedDate}
        originatingForm={originatingForm}
      />
    </ErrorBoundary>}

    {showEditLinkPurchaserId && formId && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={() => setShowEditLinkPurchaserId('')} />}>
      <UpdateServeViaLinkPurchaserModal
        formId={formId}
        formCode={formCode}
        recipientId={showEditLinkPurchaserId}
        onClose={() => setShowEditLinkPurchaserId('')}
        key='edit_link_purchaser'
      />
    </ErrorBoundary>}

    {showMarkServedRecipientId && propertyId && formId && <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} onClose={() => setShowMarkServedRecipientId('')} />}>
      <MarkServedModal
        formId={formId}
        formCode={formCode}
        recipientId={showMarkServedRecipientId}
        propertyId={propertyId}
        onClose={() => setShowMarkServedRecipientId('')}
        key='mark_served'
      />
    </ErrorBoundary>}

    {!!showServedCopyUrl && <PDFPreviewer
      url={showServedCopyUrl}
      tryMakeScrollWork={true}
      onClose={() => setShowServedCopyUrl('')}
      useCoolSpinner={true}
      key='previewer'
    />}

    <div className='my-3' key='section'>
      {recipients.filter(r=>r.serveMode).map((recipient, index) => {
        if (!recipient) return undefined;
        const served = isRecipientServed(recipient);
        const needsReServing = contractMode && (
          recipient.lastServedContactSnapshot
            ? produceContactDetailsSnapshotCompareString(recipient.lastServedContactSnapshot) !== purchasersCompareString
            : recipient.lastServedPurchasersHash !== undefined && recipient.lastServedPurchasersHash !== purchasersHash
        );

        const signedCopyFileId = recipient.manuallyServed?.signedCopy?.id;

        return <div key={index} className='d-flex flex-wrap mb-2'>
          <div className='flex-shrink-1 flex-grow-1'>
            <div className='d-flex flex-wrap' >
              <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
                <div className='fw-bold'>{recipient.name}</div>
                <div className='text-truncate'>{recipient.email}</div>
                <div>{recipient.address}</div>
              </div>
              <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
                <div className='fw-bold'>{getEventIcon(recipient, contractMode, needsReServing)}</div>
                <div>{getEventText(recipient, form1Signing?.sessionInitiator?.timeZone, contractMode)}</div>
              </div>
            </div>
          </div>
          {<div className='flex-shrink-0 flex-grow-0 mb-2 d-flex align-items-start justify-content-end signing-party-button-stack' style={{ width: '110px' }}>
            {!served && !instance?.archived &&
              <SplitIfManyButton>
                {!recipient.serveMode || recipient.serveMode === 'download' && <Button
                  variant='outline-secondary'
                  onClick={() => setShowMarkServedRecipientId(recipient.id)}
                >
                  {recipient.serveMode === 'download' ? 'Confirm' : 'Serve'}
                </Button>}
                {recipient.serveMode === 'email' && !recipient.forceReServe &&
                  (!recipient.signingSessionId || recipient.autoServe === false)?
                  <Button
                    variant='outline-secondary'
                    onClick={() => forceServe(recipient.id)}
                    title='Serve Form 1'
                  >
                    Serve
                  </Button>
                  :
                  recipient.manuallyServed && <Button
                    variant='outline-secondary'
                    onClick={() => setShowEditLinkPurchaserId(recipient.id)}
                    title='Change recipient email'
                  >
                  Edit
                  </Button>}
                {!recipient.serveMode || recipient.serveMode === 'download' && <Button
                  variant='outline-secondary'
                  onClick={() => handleDownload(recipient)}
                  title='Redownload a prefilled copy'
                >
                  Download
                </Button>}
                {recipient.serveMode === 'download' && <Button
                  variant='outline-secondary'
                  title='Cancel manual serving process'
                  onClick={()=>handleDownloadCancel(recipient)}
                >
                  Cancel
                </Button>}
              </SplitIfManyButton>}
            {!!recipient.manuallyServed && !instance?.archived &&
              <SplitIfManyButton>
                {!!signedCopyFileId &&
                  <Button
                    variant='outline-secondary'
                    onClick={() => handleViewManuallyServed(signedCopyFileId)}
                    title='View signed document'
                  >
                    View
                  </Button>}
                <Button
                  variant='outline-secondary'
                  onClick={() => setShowMarkServedRecipientId(recipient.id)}
                  title='Change signed copy'
                >
                  Edit
                </Button>
                <Button
                  variant='outline-secondary'
                  onClick={() => handleDownload(recipient)}
                  title='Redownload a prefilled copy'
                >
                  Download
                </Button>
              </SplitIfManyButton>}
            {served && !recipient.manuallyServed &&
            <SplitIfManyButton>
              {needsReServing && !instance?.archived && <Button
                variant='outline-secondary'
                onClick={() => handleReserve(recipient.id)}
                title='Re-Serve Form 1'
                disabled={isOffline}
              >
                Re-Serve
              </Button>}
              <Button
                variant='outline-secondary'
                onClick={() => handleViewServed(recipient.publishedDocumentId ?? recipient.id)}
                title='View served document'
                disabled={isOffline}
              >
                {isOffline && <Icon name='wifi_off' style={{ fontSize: '20px', marginRight: '5px' }}  />}
                View
              </Button>
              {!needsReServing && contractMode && <Button
                variant='outline-secondary'
                onClick={() => handleReserve(recipient.id)}
                title='Re-Serve Form 1'
                disabled={isOffline}
              >
                Re-Serve
              </Button>}
            </SplitIfManyButton>}
          </div>}
        </div>;
      })}
      {unserved.map((purchaser, index) => {
        // Recipient has a string here, but SigningParty uses a number
        const details = purchaser.type === 'purchaser'
          ? { name: purchaser.name, email: purchaser.email, address: purchaser.address, isRemovable: true }
          : { name: purchaser.snapshot?.company || purchaser.snapshot?.name, email: purchaser.snapshot?.email, address: purchaser.snapshot?.addressSingleLine };

        return <div key={index} className='d-flex flex-wrap'>
          <div className='flex-shrink-1 flex-grow-1'>
            <div className='d-flex flex-wrap' >
              <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
                <div className='fw-bold'>{details.name}</div>
                <div className='text-truncate'>{details.email}</div>
                <div>{details.address}</div>
              </div>
              <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
                <div className='fw-bold'><Icon name='warning' icoClass='warning-text'/> Not Served</div>
              </div>
            </div>
          </div>
          <div className='flex-shrink-0 flex-grow-0 mb-2 d-flex align-items-start justify-content-end signing-party-button-stack' style={{ width: '110px' }}>
            <SplitIfManyButton>
              <Button variant='outline-secondary' onClick={() => serveUnservedPurchaser(purchaser.id)}>Serve</Button>
              {details.isRemovable && <Button variant='outline-secondary' onClick={() => removeUnservedCustomRecipient(purchaser.id)}>Remove</Button>}
            </SplitIfManyButton>
          </div>
        </div>;
      })}
    </div>
  </WizardStepPage>;
}

export function allVendorsSigned(signing?: FormInstanceSigning): boolean {
  if (!signing?.session?.fields) return false;
  if (!signing.parties) return false;

  for (const party of signing.parties) {
    if (mapSigningPartySourceTypeToCategory(party.source.type) !== 'vendor') {
      continue;
    }

    if (signing.session.fields.some(f => f.partyId === party.id && !fieldIsSigned(f))) {
      return false;
    }
  }
  return true;
}

function getEventText(recipient: ServeStateRecipient, timeZone: string | undefined, contractMode?: boolean, needsReServing?: boolean): ReactNode {
  if (recipient.forceReServe) {
    return '';
  }
  if (contractMode && needsReServing) {
    return 'Purchaser details modified';
  }
  if (recipient.servedByEmail && (recipient.lastServedSigningSessionId && recipient.lastServedSigningSessionId !== recipient.signingSessionId)) return '';

  const servedText = recipient.reServed
    ? 'Re-Served'
    : 'Served';

  if (recipient.viewedOnline) {
    return `${servedText} online ${getTimeString(recipient.viewedOnline.timestamp, timeZone, 'at')}`;
  }

  if (recipient.servedByEmail) {
    return `${servedText} by email ${getTimeString(recipient.servedByEmail.timestamp, timeZone, 'at')}`;
  }

  if (recipient.servedBySms) {
    return `${servedText} by SMS ${getTimeString(recipient.servedBySms.timestamp, timeZone, 'at')}`;
  }

  if (recipient.manuallyServed) {
    return `${servedText} in person ${getTimeString(recipient.manuallyServed.timestamp, timeZone, 'at')}`;
  }

  if (recipient.downloaded) {
    return `Downloaded ${getTimeString(recipient.downloaded.timestamp, timeZone, 'at')}`;
  }

  if (recipient.lastEmailEvent) {
    return getEmailEventText(recipient.lastEmailEvent.type, getTimeString(recipient.lastEmailEvent.timestamp, timeZone, 'at'), 'form1');
  }

  if (recipient.timestamp) {
    return `Added ${getTimeString(recipient.timestamp, timeZone, 'at')}`;
  }

  return '';
}

function getEventIcon(recipient: ServeStateRecipient, contractMode?: boolean, needsReServing?: boolean): ReactNode {
  if (recipient.forceReServe) {
    return <div className="d-flex align-items-center"><Icon name="pending" icoClass="me-1"/>Pending</div>;
  }

  if (contractMode && needsReServing) {
    return <div className="d-flex align-items-center"><Icon name="warning" icoClass="me-1 warning-text"/>Requires Re-Serving</div>;
  }

  if (recipient.viewedOnline || recipient.manuallyServed
    || (recipient.servedByEmail && (recipient.lastServedSigningSessionId && recipient.lastServedSigningSessionId === recipient.signingSessionId))) {
    return <div className="d-flex align-items-center"><Icon name="check_circle" icoClass="me-1" style={{ color: 'green' }}/>Served</div>;
  }

  if (recipient.serveMode === 'download') {
    return <div className="d-flex align-items-center"><Icon name="schedule" icoClass="me-1"/>Confirmation Required</div>;
  }

  if (!recipient.signingSessionId || recipient.autoServe === false) {
    return <div className="d-flex align-items-center"><Icon name="warning" icoClass="me-1 warning-text"/>Requires Re-Serving</div>;
  }

  return <div className="d-flex align-items-center"><Icon name="pending" icoClass="me-1"/>Pending</div>;
}

export async function buildAndDownloadServeForm({
  ydoc,
  formDal,
  formCode,
  formId,
  name,
  address,
  contractDate,
  signingSessionId,
  timeZone,
  forceRegen
}: {
  ydoc: Y.Doc,
  formDal: PropertyFormYjsDal,
  formCode: string,
  formId: string,
  name: string,
  address: string,
  contractDate?: string,
  signingSessionId: string,
  timeZone?: string,
  forceRegen?: boolean;
}) {
  const fileName = generateFormFileName(formCode, materialisePropertyData(ydoc), false);
  const instance = formDal.getFormInstance(formCode, formId);

  // prefer to fill using the fully executed pdf if it exists.
  // particularly in non-counterpart document scenarios this is important
  if (!forceRegen) {
    if (SignedStates.has(instance?.signing?.state || FormSigningState.None)) {
      const completedFile = getCompletedFiles(instance?.signing?.session)[0];
      if (completedFile) {
        const blob = await FileStorage.readFileOnly(completedFile.id);
        if (blob) {
          downloadObjectUrl(URL.createObjectURL(blob), fileName);
          return;
        }
      }
    }
  }

  const bytes = await fillPdf(
    formDal,
    new AppFileProvider(),
    formId,
    formCode,
    signingSessionId,
    timeZone || 'Australia/Adelaide',
    true,
    false,
    {
      serveData: {
        purchaser: {
          name,
          address,
          contractDate: formatTimestamp(contractDate, undefined, false)
        }
      },
      allowUsingCompletedFile: true
    }
  );

  if (!bytes) {
    throw new Error('Could not fill PDF');
  }
  const url = URL.createObjectURL(new Blob([bytes], { type: ContentType.Pdf }));
  downloadObjectUrl(url, fileName);
}

function downloadObjectUrl(objectUrl: string, fileName: string) {
  const linkElement = document.createElement('a');
  linkElement.href = objectUrl;
  linkElement.setAttribute('download', fileName);
  document.body.appendChild(linkElement);
  linkElement.click();
  linkElement.parentNode?.removeChild(linkElement);
}
