import {
  ContentType,
  FormCodeUnion,
  FormInstance,
  MaterialisedPropertyData, Maybe
} from '@property-folders/contract';
import { useLightweightTransaction } from './useTransactionField';
import { FormUtil } from '@property-folders/common/util/form';
import { FileType } from '@property-folders/common/offline/fileStorage';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { useFileRef } from './useFileRef';
import { useEffect, useState } from 'react';
import { applyPdfChanges, injectCustomFields } from '@property-folders/common/util/pdf';

export function useFormFileRef(formId?: string, formCode?: FormCodeUnion, enabled?: boolean) {
  // load form instance details: upload file, custom fields, completed signing file(?)
  const parentPath = formId
    ? FormUtil.getFileFormPath({ id: formId, code: formCode })
    : undefined;
  const { value: form } = useLightweightTransaction<FormInstance>({
    parentPath,
    bindToMetaKey: true,
    // always in the root, even if the requesting document is in a sublineage
    ydocForceKey: PropertyRootKey.Meta
  });
  // use data from the current sublineage
  const { value: property, transactionRootKey } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath: '' });

  const fileId = enabled
    ? form?.upload?.id
    : undefined;
  const contentType = form?.upload?.contentType ?? ContentType.Pdf;

  const baseFileData = useFileRef(
    fileId,
    FileType.PropertyFile,
    contentType as ContentType,
    {});

  const [resultFile, setResultFile] = useState<Maybe<Blob>>(undefined);
  const [status, setStatus] = useState<'loading' | 'ready' | 'none' | 'error'>(formId ? 'loading' : 'none');

  useEffect(() => {
    if (!formId) {
      setResultFile(undefined);
      setStatus('none');
    }
  }, [formId]);

  useEffect(() => {
    if (!enabled) return;
    if (!baseFileData) return;

    const ac = new AbortController();
    setStatus('loading');
    (async () => {
      if (!property) return;
      try {
        const result = await applyPdfChanges(
          await baseFileData.arrayBuffer(),
          [
            pdf => injectCustomFields({
              customFields: form?.signing?.customFields ?? [],
              pdf,
              fieldIdMap: new Map(),
              parties: form?.signing?.parties ?? [],
              property
            }),
            pdf => {
              pdf.getForm().flatten();
              return Promise.resolve();
            }
          ]
        );
        if (ac.signal.aborted) return;
        setResultFile(new Blob([result], { type: contentType }));
        setStatus('ready');
      } catch (err: unknown) {
        console.error(err);
        setStatus('error');
      }
    })();

    return () => {
      ac.abort();
    };
  }, [enabled, baseFileData, form?.signing?.customFields, property, contentType]);

  return { file: resultFile, status };
}
